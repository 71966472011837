import { useState } from "react";
import PostModal from "./PostModal/PostModal";
import "./post.scss";
import "./post.css";

const GridPost = ({ post, postId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Determine the media content type (image or video)
  const isVideo = post.media.type === 'video/mp4';

  return (
    <div className="w-full h-full aspect-square">

      <div className="post-container" onClick={() => openModal()} >

        {/* {isVideo ? (
          
          <video
            className="w-full h-full aspect-square hover:cursor-pointer object-cover hover:ring-2 hover:ring-white rounded-sm hover:brightness-50 transition-all"
            controls
            onClick={() => openModal()}
          >

            <source
              src={`data:${post.media.type};base64,${post.media.content}`}
              type="video/mp4"
            />

            Your browser does not support the video tag.

          </video>

        ) : (
          
          <img

            src={`data:${post.media.type};base64,${post.media.content}`}
            alt=""
            className="w-full h-full aspect-square hover:cursor-pointer object-cover hover:ring-2 hover:ring-white rounded-sm hover:brightness-50 transition-all"
            onClick={() => openModal()}

          />

        )} */}

        <img
          src={`data:${post.thumbnail.type};base64,${post.thumbnail.content}`}
          alt=""
          className="w-full h-full aspect-square hover:cursor-pointer object-cover hover:ring-2 hover:ring-white rounded-sm hover:brightness-50 transition-all"
          onClick={() => openModal()}
        />

        <div className="hover-title hover:cursor-pointer">
          
          <p className="title">{post.title}</p>

        </div>

      </div>

      {isModalOpen && (
        <PostModal post={post} postId={postId} closeModal={closeModal} />
      )}

    </div>
  );
};

export default GridPost;
