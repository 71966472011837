import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ambientePic from '../../../../assets/Landing/Wave-Ambiente.png';
import centriPic from '../../../../assets/Landing/Wave-Centri-Culturali.png';
import mobilitaPic from '../../../../assets/Landing/Wave-Mobilità.png';
import serviziPic from '../../../../assets/Landing/Wave-Comunità.png';
import talentiPic from '../../../../assets/Landing/Wave-Talenti.png';
import ambienteCarousel from '../../../../assets/Landing/Carousel-Ambiente.png';
import centriCarousel from '../../../../assets/Landing/Carousel-Centri.png';
import mobilitaCarousel from '../../../../assets/Landing/Carousel-Mobilità.png';
import serviziCarousel from '../../../../assets/Landing/Carousel-Centri.png';
import talentiCarousel from '../../../../assets/Landing/Carousel-Talenti.png';
import LandingFooter from "../LandingFooter/LandingFooter";
import ScrollToTop from "../../../../ScrollToTop";

const categoryData = [
  {
    categoryName: "Ambiente",    
    categorySubText: "VOCI crede nella promozione delle cause ambientali e della sostenibilità nelle nostre città. La categoria environment mette in mostra individui e organizzazioni dedicate all’informazione ambientale e alla creazione di un futuro più verde e sostenibile.",
    firstTitle: "Ascolta l'ambiente intorno a te!",
    firstSubtitle: "Troverai informazioni su flora e fauna del tuo territorio e scoprirai aziende, organizzazioni e persone che si dedicano alla protezione dell’ambiente e ad attività socialmente utili per la pulizia e la conservazione di parchi e spiagge.",
    image: ambientePic,
    carouselExampleImg: ambienteCarousel,
    path: "/home/ambiente",
  },
  {
    categoryName: "Centri Culturali",      
    categorySubText: "VOCI promuove la bellezza e la diversità della nostra cultura offrendo visibilità a tutte le iniziative proposte dai centri culturali presenti sul territorio.",
    firstTitle: "Esplora la cultura che ti circonda!",
    firstSubtitle: "Troverai informazioni su mostre, concerti e attività che si svolgono all’interno dei centri culturali presenti sul suolo cittadina. Questo è lo spazio dove musei, scuole, università o stadi posso promuovere nuove notizie o attività!",
    image: centriPic,
    carouselExampleImg: centriCarousel,
    path: "/home/centri-culturali",
  },
  {
    categoryName: "Mobilità",      
    categorySubText: "VOCI vuole favorire il trasporto cittadino dando spazio agli enti che si occupano di trasporti in modo tale che i cittadini possano trovare facilmente informazioni dettagliate sui trasporti pubblici, parcheggi disponibili e itinerari cittadini.",
    firstTitle: "Scopri come muoverti al meglio in città!",
    firstSubtitle: "In questa categoria troverai informazioni sui mezzi pubblici e privati disponibili, potrai individuare parcheggi disponibili e scoprire itinerari unici offerti sul territorio!",
    image: mobilitaPic,
    carouselExampleImg: mobilitaCarousel,
    path: "/home/mobilita",
  },
  {
    categoryName: "Servizi per la comunità",      
    categorySubText: "VOCI mira a supportare tutti gli enti e le persone che si dedicano ad avere un impatto positivo sulla comunità cittadina. In questa categoria potrai trovare informazioni utili su servizi pubblici, eventi locali, iniziative solidali e tanto altro ancora!",
    firstTitle: "Scopri le iniziative che rendono la tua città un posto migliore!",
    firstSubtitle: "Scopri opportunità di volontariato, servizi sociali e iniziative comunitarie che ti permetteranno di connetterti con le persone intorno a te e rendere la tua città un luogo migliore!",
    image: serviziPic,
    carouselExampleImg: serviziCarousel,
    path: "/home/servizi-per-la-comunita",
  },
  {
    categoryName: "Talenti",      
    categorySubText: "VOCI aiuta a mettere in luce i talenti locali offrendo l’opportunità di coltivare, esplorare e condividere le proprie capacità. Sia che tu sia un talento emergente alla ricerca di opportunità di crescita o semplicemente un appassionato che desidera condividere la propria arte con gli altri, la nostra piattaforma è il posto giusto per te!",
    firstTitle: "Scopri una vasta gamma di talenti diversi!",
    firstSubtitle: "La piattaforma ti offre l'opportunità unica di scoprire, connetterti con i talenti della tua città e diventare parte della loro crescita!",
    image: talentiPic,
    carouselExampleImg: talentiCarousel,
    path: "/home/talenti",
  },
];

function CategoryPage () {
  const navigate = useNavigate();
  const location = useLocation();

  const goBack = () => {
    navigate(-1);
  };

  // Find the category object that matches the current path
  const currentPath = location.pathname;
  const category = categoryData.find(category => category.path === currentPath);

  if (!category) {
    // Handle the case where the category is not found, e.g., display an error message
    return (
      <div>
        Category not found.
      </div>
    );
  }

  return (
 
    <div className="flex flex-col w-full justify-center items-center">

      <ScrollToTop />
  
      <div className="flex w-full h-[780px] relative">

        <div className="flex -mb-24">
    
          <img src={category.image} alt={category.categoryName} className="w-full object-contain" />

        </div>
    
        <div className="absolute top-0 left-0 w-full h-full gap-[10rem] flex flex-col justify-start items-center">
      
          <div onClick={goBack} className="flex self-start ml-16 mt-[8rem] p-4 rounded-full text-white bg-opacity-70 hover:bg-[#313033] hover:cursor-pointer ">
      
            <ArrowBackIcon />
      
          </div>
    
          <div className="flex flex-col w-1/2 justify-center items-center gap-8">
      
            <h1 className="text-white font-bold text-[5rem]"> {category.categoryName} </h1>
        
            <p className="text-white font-medium text-[1.8rem]"> {category.categorySubText} </p>
    
          </div>

        </div>

      </div>

      <div className="flex flex-col justify-center items-center my-40">

        <div className="flex flex-col self-start ml-20 my-80">

          <h1 className="text-[3rem] font-bold text-white"> {category.firstTitle} </h1>

          <div className="flex w-[40%]">

            <h4 className="text-[1.4rem] font-medium text-left text-white"> {category.firstSubtitle}  </h4>

          </div>

        </div>

        <div className="w-full">

          <img src={category.carouselExampleImg} alt="Carousel Image Example" />

        </div>

      </div>

      <LandingFooter />

    </div>

  );
}

export default CategoryPage;
