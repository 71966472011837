import { makeRequest } from "./axios";

export const fetchPostsByUserId = async (userId) => {
  try {
    const res = await makeRequest.get(`/posts?userId=${userId}`);
    console.log("Data from fetchPostsByUserId:", res.data); // Log the response data
    return res.data;
  } catch (error) {
    console.error("Error in fetchPostsByUserId:", error);
    throw error; // Re-throw the error to handle it elsewhere if needed
  }
};

export const logout = async() => {
  try {
    const res = await makeRequest.post("/auth/logout");
    console.log("Data from logout:", res.data); // Log the response data
    return res.data;
  } catch (error) {
    console.error("Error in logout:", error);
    throw error; // Re-throw the error to handle it elsewhere if needed
  }
};

export const fetchAllPosts = async () => {
  try {
    const res = await makeRequest.get("/posts");
    console.log("Data from fetchAllPosts:", res.data); // Log the response data
    return res.data;
  } catch (error) {
    console.error("Error in fetchAllPosts:", error);
    throw error; // Re-throw the error to handle it elsewhere if needed
  }
};

export const fetchAllDashboardPosts = async () => {
  try {
    const res = await makeRequest.get("/posts/dashboard");
    console.log("Data from fetchAllDashboardPosts:", res.data); // Log the response data
    return res.data;
  } catch (error) {
    console.error("Error in fetchAllDashboardPosts:", error);
    throw error; // Re-throw the error to handle it elsewhere if needed
  }
};


export const getCommentsText = async (postId) => {
  const res = await makeRequest.get(`/comments?postId=${postId}`);
  return res.data;
};

export const getPostInfo = async (postId) => {
  const res = await makeRequest.get("/posts/info?postId=" + postId);
  console.log(res.data);
  return res.data;
};

export const likeaPost = async (postId) => {
  const res = await makeRequest.post("/likes", { postId });
  return res.data;
};

export const getUserId = (req, res) => {
  const userdata = localStorage.getItem("user");
  const user = userdata ? JSON.parse(userdata) : null;
  // Make sure the user exists and has an ID
  const userId = user ? user.id : null;
  return userId;
};

export const getAllUsers = async () => {
  const res = await makeRequest.get("/users/getAll");
  return res.data;
};


export const fetchAllChatUsers = async () => {
  try {
    const res = await makeRequest.get("/dm/getAllUsers");
    return res.data;
  } catch (error) {
    console.error("Error in fetchAllChatUsers:", error);
    throw error; // Re-throw the error to handle it elsewhere if needed
  }
};

export const fetchAllChatMessages = async (data) => {
  try {
    const res = await makeRequest.post("/dm/getChat", data);
    return res.data;
  } catch (error) {
    console.error("Error in fetchAllChatMessages:", error);
    throw error; // Re-throw the error to handle it elsewhere if needed
  }
};

export const writeMessage = async (data) => {
  try {
    const res = await makeRequest.post("/dm/write", data);
    return res.data;
  } catch (error) {
    console.error("Error in writeMessage:", error);
    throw error; // Re-throw the error to handle it elsewhere if needed
  }
};

export const modifyUser = async (userData) => {
  try {
    const res = await makeRequest.post("/users/update", userData);
    return res.data;
  } catch (error) {
    throw error; // Re-throw the error to handle it elsewhere if needed
  }
};

export const getAllStories = async () => {
  try {
    const res = await makeRequest.get('/stories');
    console.log("Data from getAllStories:", res.data); // Log the response data
    return res.data;
  } catch (error) {
    // Handle errors here
    console.error("Failed to fetch stories:", error);
    throw error; // You can choose to re-throw the error or handle it differently
  }
};

export const getAllVideos = async (data) => {
  try {
    const res = await makeRequest.post("/totem/playlist", data);
    return res.data;
  } catch (error) {
    // Handle errors here
    console.error("Failed to fetch videos:", error);
    throw error; // You can choose to re-throw the error or handle it differently
  }
};

export const handleLike = async (commentId) => {
  try {
    const res = await makeRequest.post("/likes", {
      commentId,
    });
    if (res.status === 200) {
      return res.data;
    } else {
      throw new Error("Failed to like comment");
    }
  } catch (error) {
    console.error("Error in handleLike:", error);
    throw error; // Re-throw the error to handle it elsewhere if needed
  }
};

export const handleDislike = async (commentId) => {
  try {
    const res = await makeRequest.post("/likes/delete", {
      commentId,
    });
    if (res.status === 200) {
      return res.data;
    } else {
      throw new Error("Failed to dislike comment");
    }
  } catch (error) {
    console.error("Error in handleDisike:", error);
    throw error; // Re-throw the error to handle it elsewhere if needed
  }
};