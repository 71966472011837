import React, { useEffect, useState, useRef } from 'react';

import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import './ChatPage.scss';
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import DraftsIcon from '@mui/icons-material/Drafts';

import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { AuthContext } from "../../context/authContext";
import { useContext } from "react";

import { fetchAllChatUsers, fetchAllChatMessages, writeMessage } from "../../FetchCalls";

import { useNavigate, Outlet } from 'react-router-dom';
import "./index.css";
const ChatPage = () => {

  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [chats, setChats] = useState([]);
  const [typingStatus, setTypingStatus] = useState('');
  const lastMessageRef = useRef(null);
  const [currentChat, setCurrentChat] = useState(null); //TODO: Salvare stato a livello più alto per ricordarlo anche quando /chat viene chiuso, oppure salvarlo nel local storage per lo stesso motivo e aprire automaticamente la chat al lancio oppure aprire automaticamente la chat più recente al lancio (ma allora si rischia di aprire una chat che l'utente non voleva aprire)

  const [sendMessageDisabled, setSendMessageDisabled] = useState(true);
  const { currentUser } = useContext(AuthContext);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchAllChatUsers();
        setChats(data);
      }
      catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchDataMessage = async () => {
      if (currentChat) {
        try {
          const input = {
            userId: currentUser.id,
            selectedId: currentChat.id,
          };
          const data = await fetchAllChatMessages(input);
          setMessages(data);
        }
        catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
    fetchDataMessage();
  }, [currentChat]);




  const testChats = [ //These will be ordered by most recent by the server (hopefully)
    {
      id: 1,
      user: {
        profilePic: "thumbnail_index2.jpeg",
        name: "Tal dei Tali"
      },
      toRead: false,
      latestMessage: {
        fromYou: true,
        timeFromReception: "20s", //This may be done on the client, but I would calc this on the server
        body: "Ci vediamo lì!"
      }
    },
    {
      id: 2,
      user: {
        profilePic: "thumbnail_index2.jpeg",
        name: "Organizzazione Nomi Lunghissimi"
      },
      toRead: false,
      latestMessage: {
        fromYou: false,
        timeFromReception: "3d", //This may be done on the client, but I would calc this on the server
        body: "Che nome corto che ha! Sarebbe interessato in una procedura di allunegamento del nome?"
      }
    },
    {
      id: 3,
      user: {
        profilePic: "thumbnail_index2.jpeg",
        name: "Maria Grazia"
      },
      toRead: true,
      latestMessage: {
        fromYou: false,
        timeFromReception: "1 sett", //This may be done on the client, but I would calc this on the server
        body: "Dobbiamo parlare, ho visto cosa hai fatto"
      }
    },
    {
      id: 4,
      user: {
        profilePic: "thumbnail_index2.jpeg",
        name: "Persona muta"
      },
      toRead: false,
      latestMessage: {
        fromYou: false,
        timeFromReception: "3d", //This may be done on the client, but I would calc this on the server
        body: "😶"
      }
    }
  ]


  const testMessages = [ //Assuming ordered by less recent
    {
      fromYou: false,
      body: "Quando hai in programma il prossimo concerto?"
    },
    {
      fromYou: true,
      body: "Ciao!"
    },
    {
      fromYou: true,
      body: "Ancora non ho organizzato nulla, ma vi terrò aggornati!"
    },
    {
      fromYou: false,
      body: "Okay Grazie!"
    },
    {
      fromYou: false,
      body: "Alla prossima!"
    }
  ]

  const [chatSearchString, setChatSearchString] = useState(null); //TODO: Use this to dynamically filter chats without needing to press Enter? (Similar to many socials)

  function closeHandler() {
    navigate('..');
  }


  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);


  const handleChange = (e) => {
    const newString = e.target.value.trim() === "" ? null : e.target.value.trim();

    setChatSearchString(newString);
  }

  const showChatOptions = () => {
    document.getElementById('chat-options').classList.add("show");
    document.getElementById('chat-options').focus();
  }

  const hideChatOptions = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      document.getElementById('chat-options').classList.remove("show");
    }
  }

  const deleteChatPressed = () => {
    //TODO
    alert('TODO');
  }

  const blockUserPressed = () => {
    //TODO
    alert('TODO');
  }

  const handleChatSelected = (userId) => {
    //TODO: Aggiornare stato della chat e mettere toRead a false
    setMessages([])
    setCurrentChat(chats.filter(chat => chat.id === userId)[0]);

    // const data = {
    //   // find the id of the city
    //   userId: currentUser.id,
    //   //2023-09-16T17:17:11.000Z format to day/month/year
    //   selectedId: currentChat.userId,
    // };
    //setMessages(await getChatByIds(data));
  }

  const handleSendMessage = async (e) => {
    e.preventDefault();
    try {
      let message = e.target.elements.message.value
      let chat_id = null;
      if (messages[0] != null)
        chat_id = messages[0].chat_id
      const data = {
        text: message,
        myID: currentUser.id,
        reciverID: currentChat.id,
        chat_id: chat_id
      }

      const res = await writeMessage(data);
      setMessages([...messages, { body: message, fromYou: true }]);
      e.target.reset();
    }
    catch (error) {
      console.error("Error fetching data:", error);
    }


  }

  const handleMessageChange = (e) => {
    if (e.target.value.trim() !== "") {
      setSendMessageDisabled(false);
    } else {
      setSendMessageDisabled(true);
    }
  }

  const resizeTextarea = (e) => {
    if (e) {
      e.target.style.height = "";
      e.target.style.height = e.target.scrollHeight + "px";
    } else {
      document.getElementById("message-textarea").style.height = "";
      document.getElementById("message-textarea").style.height = document.getElementById("bio-textarea").scrollHeight + "px";
    }
  }

  useEffect(() => {
    const mainChatDiv = document.getElementById("open-chat-main-div");
    if (mainChatDiv != null) mainChatDiv.scrollTo(0, mainChatDiv.scrollHeight);
  })

  return (
    <div className='chatModalOuterDiv'>
      <div className='chatInnerGrid'>
        <button className="closeBtn" onClick={closeHandler}>
          <ArrowBackRoundedIcon className="icon" />
        </button>

        <div className='sideBar'>
          <h2>Messaggi</h2>

          <div className='searchDiv'>
            {/* TODO: Assuming this is also going to search amongst all users on the platform, we are going to add a section to ChatlistScrollable afterwards */}
            <SearchOutlinedIcon className='searchIcon' />
            <input
              type="text"
              name="chatSearchString"
              placeholder="Cerca un utente"
              onChange={handleChange}
            />
          </div>

          <div className='chatListScrollable'>
            {chats.filter(chat => chatSearchString == null || chat.user.name.toLowerCase().includes(chatSearchString.toLowerCase())).map(chat =>
              <div className={'chatPreviewDiv' + (currentChat?.email === chat.email ? ' selected' : '')} onClick={() => handleChatSelected(chat.id)}>
                {chat.toRead &&
                  <div className='notificationDot' />
                }
                <img srcset={"/upload/" + chat.user?.profilePic} />
                <h4 title={chat.user?.name}>{chat.user?.name + "  " + chat.user?.surname}</h4>
                {/* //<div className={'chatInfo' + (chat.toRead ? ' toRead' : '')}>
                  
                   <div className='chatPreview' title={chat.latestMessage?.body}>
                    <h6>{(chat.latestMessage?.fromYou ? "Tu: " : "") + chat.latestMessage?.body}</h6>
                    {!chat.latestMessage?.fromYou &&
                      <h6 className='timeFromReception'>{"· " + chat.latestMessage?.timeFromReception}</h6>
                    }
                  </div> 
                </div>*/}
              </div>
            )

            }
          </div>
        </div>

        <div className='openChatDiv'>
          {currentChat == null &&
            <div className='noChatDiv'>
              <DraftsIcon className='icon' />
              <p>Seleziona una chat o scrivi a un amico per iniziare!</p>
            </div>
          }

          {currentChat != null &&
            <>
              <div className='openChatHeader'>
                <div className='userInfo'>
                  <img srcset={"/upload/" + currentChat.user?.profilePic} />
                  <h2 title={currentChat.user?.name}>{currentChat.user?.name}</h2>
                </div>

                {/* <button className="moreBtn" onClick={showChatOptions}>
                    <MoreHorizIcon className="icon" />
                  </button>

                  <div id='chat-options' tabindex="1" className='optionsTooltip' onBlur={hideChatOptions}>
                    <button onClick={deleteChatPressed}>
                      Elimina chat
                    </button>
                    <button onClick={blockUserPressed}>
                      Blocca utente
                    </button>
                  </div> */}
              </div>

              <div id="open-chat-main-div" className='openChatMainDiv'>
                {messages.map((msg, index) =>
                  <div //Il gran casino di className imposta una distanza ridotta tra messaggi consecutivi dalla stessa persona
                    className={'msgDiv' +
                      (msg.fromYou
                        ?
                        (' msgSent'
                          +
                          (((messages.length !== index + 1) && (messages[index + 1].fromYou)) ? ' reducedMargin' : ''))
                        :
                        (' msgReceived'
                          +
                          (((messages.length !== index + 1) && (!messages[index + 1].fromYou)) ? ' reducedMargin' : '')))}
                  >
                    {msg.fromYou &&
                      <div className='msgBubble fromYou'>
                        <p>{msg.body}</p>
                      </div>
                    }

                    {!msg.fromYou &&
                      <>
                        {/* <img
                          className={((messages.length === index + 1) || (messages[index + 1].fromYou)) ? 'profilePic' : 'profilePic invisibile'}
                          srcset={"/upload/" + currentChat.user?.profilePic}
                        /> */}
                        <div className='msgBubble fromOther'>
                          <p>{msg.body}</p>
                        </div>
                      </>
                    }
                  </div>
                )}
              </div>

              <div className='formDiv'>
                <form onSubmit={handleSendMessage}>
                  <textarea
                    id="message-textarea"
                    type="text"
                    name="message"
                    onChange={handleMessageChange}
                    rows="1"
                    onInput={(e) => resizeTextarea(e)}
                    placeholder='Scrivi un messaggio...'
                  />

                  <button disabled={sendMessageDisabled}>
                    <SendRoundedIcon className="sendIcon" />
                  </button>
                </form>
              </div>
            </>
          }
        </div>
      </div>
    </div>
  )

};

export default ChatPage;