import { useContext } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from "../../context/authContext";
import HomeIcon from '@mui/icons-material/Home';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import "./leftBar.scss";

const LeftBar = () => {

  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const goToHome = () => {
    navigate("/home");
  };

  const goToExplore = () => {
    navigate("/home/explore");
  };

  const goToCreate = () => {
    navigate("/home/create-content-home");
  };

  const goToProfile = () => {
    navigate(`/home/profile/${currentUser.id}`);
  };

  return (

    <div className="leftBar">

      <div className="container">

        <div className="menu">

          <div className="flex" title='Home'>

            <button onClick={goToHome} className={"flex flex-row justify-center items-center gap-4 hover:underline" + (location.pathname === "/" ? " underline" : "")} >

              <div className="flex w-12 justify-center items-center">

                <HomeIcon />

              </div>
              
              <p> Home </p>

            </button>
       
          </div>

          <div className="flex" title='Esplora'>

            <button onClick={goToExplore} className={"flex flex-row justify-center items-center gap-4 hover:underline" + (location.pathname.includes("/explore") ? " underline" : "")} >

              <div className="flex w-12 justify-center items-center">

                <ExploreOutlinedIcon />

              </div>

              <p> Esplora </p>

            </button>
       
          </div>

          <div className="flex" title='Nuovo'>

            <button onClick={goToCreate} className={"flex flex-row justify-center items-center gap-4 hover:underline" + (location.pathname.includes("create-content-home") ? " underline" : "")} >

              <div className="flex w-12 justify-center items-center">

                <AddBoxOutlinedIcon />
              
              </div>

              {/* <p> Crea Nuovo </p> */}
              <p> Nuovo </p>

            </button>
       
          </div>

          <div className="flex" title='Profilo'>

            <button onClick={goToProfile} className={"flex flex-row justify-start items-center gap-4 hover:underline" + (location.pathname.includes("profile") ? " underline" : "")} >

              <img src={"/upload/" + currentUser.profilePic} alt="" className="w-8 h-8 mr-2 ml-2 rounded-full object-cover bg-[#C0afe2]"/>

              <p> Profilo </p>

            </button>
       
          </div>

        </div>

      </div>

    </div>

  );
};

export default LeftBar;