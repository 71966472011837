import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import LocationOnIcon from "@mui/icons-material/LocationOn";

function TotemSelect({ totems, toggleCheck, isChecked, handleSearch }) {
  return (
    <div className="flex flex-col w-full">
      <div className="flex w-fit self-end mb-4 border-b-2 border-[#E6E1E5]">
        <input
          type="text"
          placeholder="Cerca una via specifica"
          onChange={(e) => handleSearch(e.target.value)}
          className="text-sm px-2 py-1 outline-none"
        />

        <SearchIcon className="text-white" />
      </div>

      {totems && totems.length > 0 ? (
        totems.map((item, index) => (
          <button
            key={item.id}
            onClick={() => toggleCheck(item.id)}
            className="flex flex-row w-full h-10 pr-4 justify-between items-center rounded-md gap-4 text-[#707070] hover:bg-[#1C1B1F] hover:text-white"
          >
            <div
              className={`${isChecked(
                item
              )} flex flex-row justify-center items-center gap-2`}
            >
              <LocationOnIcon className="text-white"/>

              <p className="text-lg font-medium"> {item.address} </p>

            </div>

            <input
              value={item.id}
              id={"checkbox" + item.id}
              type="checkbox"
              checked={item.checked || false}
              className="checkbox-input"
              onChange={() => {}}
            />
          </button>
        ))
      ) : (
        <span className="text-white"> Nessun Totem Disponibile </span>
      )}
    </div>
  );
}

export default TotemSelect;
