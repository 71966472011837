import Posts from "../../components/posts/Posts";
import { Outlet, Route } from "react-router-dom";
import Carousel from "../../components/carousel/Carousel";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getUserId, fetchAllDashboardPosts, getAllStories } from "../../FetchCalls";
import "./home.scss";
import { useEffect } from "react";
import { TailSpin } from 'react-loader-spinner';

const Home = () => {

  // Get user data from localStorage
  // Get the token from cookies

  const userId = getUserId();
  const queryClient = useQueryClient();
  const posts = useQuery(["allposts"], () => fetchAllDashboardPosts(), {});
  const stories = useQuery(["allposts","allstories"], () => getAllStories(), {}); //TODO: Valutare se sostituire allposts a un altro nominativo tipo allstories, il vantaggio attuale � che il refetch per le storie si attiva quando si attiva quello per tutti i post

  // useEffect to refetch posts when the userId changes


  // const refetchPosts = () => {
  //   queryClient.invalidateQueries(["allposts", userId]);
  // };

  const DisableScrolling = () => { //TODO: Da passare ai singoli post per chiamarla quando il modale viene aperto
    document.getElementById("home-div").style.overflow = "hidden"; //Generic
    document.getElementById("home-div").style.touchAction = "none"; //For mobile devices
  }

  const EnableScrolling = () => { //TODO: Da passare ai singoli post per chiamarla quando il modale viene chiuso
    document.getElementById("home-div").style.overflow = "auto"; //Generic
    document.getElementById("home-div").style.touchAction = "auto"; //For mobile devices
  }

  if(posts.isLoading || stories.isLoading) return (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <TailSpin
        color="#FFFFFF" // Set the loader color
        height={80} // Set the height of the loader
        width={80} // Set the width of the loader
      />
    </div>
  );

  return (

    <div id="home-div" className="home">

      {stories.isSuccess &&

      <div className="carousel-container">

        <Carousel posts={stories.data} />

        <div className="divider" />

      </div>

      }

      <Outlet />

      {posts.isSuccess &&
        <Posts posts={posts.data}/>
      }

    </div>

  );
  
};

export default Home;
