import axios from "axios";
import Cookies from 'js-cookie';

const token = Cookies.get('accessToken');

const makeRequest = axios.create({
  baseURL: "https://api.voiceint.wwwnl1-ts107.a2hosted.com/api/",
  withCredentials: true
});

makeRequest.defaults.headers.common["Authorization"] = `Bearer ${token}`;
makeRequest.defaults.headers.common["Content-Type"] = "application/json";

export { makeRequest };
