import React, { useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import CityCarousel from "./Components/CityCarousel/CityCarousel";
import DownArrow from '@mui/icons-material/South';
import HeroLogo from '../../assets/Landing/Hero-Logo.png';
import RainbowWave from '../../assets/Landing/Rainbow-Wave.png';
import WebsiteExample from '../../assets/Landing/Website-Example.png';
import SubscribeIllustration from '../../assets/Landing/Subscribe-Illustration.png';
import ArrowRightIllustration from '../../assets/Landing/Arrow-Right-Illustration.png';
import PublishIllustration from '../../assets/Landing/Publish-Illustration.png';
import ArrowLeftIllustration from '../../assets/Landing/Arrow-Left-Illustration.png';
import MeetIllustration from '../../assets/Landing/Meet-Illustration.png';
import RainbowCircle from '../../assets/Landing/Rainbow-Circles.png';
import LandingFooter from "./Components/LandingFooter/LandingFooter";
import './Landing.css';
import ScrollToTop from "../../ScrollToTop";
import footer from '../../assets/footer.png';

const categoryData = [
  {
    name: "Ambiente",
    color: "#1BC07A",
    path: "/home/ambiente",
  },
  {
    name: "Centri Culturali",
    color: "#E7D849",
    path: "/home/centri-culturali",
  },
  {
    name: "Mobilità",
    color: "#2848CD",
    path: "/home/mobilita",
  },
  {
    name: "Servizi per la Comunità",
    color: "#F1A449",
    path: "/home/servizi-per-la-comunita",
  },
  {
    name: "Talenti",
    color: "#D41882",
    path: "/home/talenti",
  },
];

function Landing() {
  const navigate = useNavigate();
  const rainbowWaveRef = useRef(null);

  const goToRegister = () => {
    navigate('/register');
  };

  const goToLogin = () => {
    navigate('/login');
  };

  const handleButtonClick = () => {
    // Scroll to the rainbowWave section when the button is clicked
    if (rainbowWaveRef.current) {
      rainbowWaveRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (

    <div className="flex flex-col w-full justify-center items-center">

      <ScrollToTop />
      <div class="footer">
<li class="socialMedias"> <img class="foot" src={footer}/>
</li>
</div>
 
      <div className="flex flex-col h-screen justify-center items-center">
  
        <img src={HeroLogo} alt="Voci Logo" className="w-full h-fit" />
  
        <button onClick={handleButtonClick} className="w-20 h-20 -mt-[150px] rounded-full border-2 border-[#FFFBFE] transition-all ease-in-out hover:translate-y-4">
  
          <DownArrow className="text-[#FFFBFE]" />
  
        </button>
   
      </div>
    
      <div className="flex flex-col -mb-[5rem] justify-center items-center relative">

        <div ref={rainbowWaveRef} className="flex h-[50vh] justify-center items-center">
    
          <img src={RainbowWave} alt="Ranibow Wave" className="w-full h-fit" />

          <div className="text-content-container flex flex-col w-full justify-center items-center">
      
            <h1 className="text-[6rem] font-bold text-white"> La città prende voce </h1>
            <h4 className="text-[1.8rem] font-medium text-center text-white"> VOCI è il social che fornisce uno spazio a talenti, imprese, <br/> strutture e enti attivi sul territorio campano. </h4>
      
            <button onClick={goToRegister} className="flex w-fit h-12 my-10 px-8 justify-center items-center rounded-3xl text-[#1C1B1F] bg-white border-2 border-[#1C1B1F] hover:text-white hover:bg-[#1C1B1F] hover:border-white">
      
              <p className="text-xl font-bold"> Iscriviti </p>
      
            </button>

            <button onClick={goToLogin} className="flex w-fit h-12 my-10 px-8 justify-center items-center rounded-3xl text-[#1C1B1F] bg-white border-2 border-[#1C1B1F] hover:text-white hover:bg-[#1C1B1F] hover:border-white">
      
              <p className="text-xl font-bold"> Accedi </p>

            </button>
      
          </div>

        </div>

      </div>

      <div className="flex flex-col w-[90%] mb-20 justify-center items-center gap-40 z-10">

        <div className="flex justify-center items-center">

          <img src={WebsiteExample} alt="Website Example" className="w-[70%] h-auto" />

        </div>

        <div className="flex flex-col w-1/2 self-start">
          
          <h1 className="text-[4rem] font-bold text-white"> Dai social al sociale </h1>

          <div className="flex w-[70%]">

            <h4 className="text-[1.4rem] font-medium text-left text-white"> VOCI promuove la ricca diversità culturale che esiste all’interno delle città. Crediamo che il talento locale sia la linfa vitale della comunità cittadina, per questo aiutiamo artisti, imprese e attivisti locali a far sentire la loro voce trasmettendo i loro contenuti su appositi totem distribuiti sul territorio cittadino. </h4>
          
          </div>

        </div>

        <div className="flex justify-center items-center">

          <img src={SubscribeIllustration} alt="Website Example" className="w-[70%] h-auto" />

        </div>

        <div className="flex flex-col w-1/2 justify-center items-center">
          
          <h1 className="text-[4rem] font-bold text-white"> Conosci la tua comunità </h1>

          <div className="flex w-[90%]">

            <h4 className="text-[1.4rem] font-medium text-center text-white"> Iscrivendoti alla piattaforma avrai l’occasione di scoprire tutto ciò che avviene nella tua città. Scopri personaggi, talenti e iniziative attive sul tuo territorio, entra a far parte della vita cittadina! </h4>
          
          </div>

        </div>

        <div className="flex flex-row w-full justify-center items-center">

          <div className="flex flex-col w-1/2 self-start -mt-[22rem]">

            <img src={ArrowRightIllustration} alt="Website Example" className="h-auto" />

            <div className="flex flex-col self-start mt-16">
              
              <h1 className="text-[3rem] font-bold text-white"> Pubblica contenuti <br/> e fatti conoscere <br/> dalla tua città! </h1>

              <div className="flex w-[70%]">

                <h4 className="text-[1.4rem] font-medium text-left text-white"> I post pubblicati verranno trasmessi su totem pubblicitari sparsi per la città, permettendoti così di farti conoscere sul territorio cittadino. Che tu sia un talento emergente, un’azienda, o una persona attiva socialmente, tramite VOCI potrai pubblicizzare te stesso e le tue attività! </h4>
              
              </div>

            </div>

          </div>

          <div className="flex w-1/2">

            <img src={PublishIllustration} alt="Website Example" className="h-auto" />

          </div>

        </div>

        <div className="flex flex-row w-full justify-center items-center">

          <div className="flex w-1/2">

            <img src={MeetIllustration} alt="Website Example" className="h-auto" />

          </div>

          <div className="flex flex-col w-1/2 self-start -mt-[22rem]">

            <img src={ArrowLeftIllustration} alt="Website Example" className="h-auto -ml-[10rem]" />

            <div className="flex flex-col ml-32 mt-16">
              
              <h1 className="text-[3rem] font-bold text-white"> Incontra la tua <br/> community! </h1>

              <div className="flex">

                <h4 className="text-[1.4rem] font-medium text-left text-white"> Tramite VOCI i totem diventano punto d’incontro sociale. Attraverso la piattaforma potrai organizzare dei veri e propri eventi, chiamati Meet&Greet, occupando gratuitamente lo spazio circostante del totem scelto! </h4>
              
              </div>

            </div>

          </div>

        </div>

        <div className="flex flex-col justify-center items-center">
  
          <h1 className="text-[3rem] font-bold text-white"> Scopri le città aderenti </h1>

          <CityCarousel />

        </div>

      </div>
    
      <div className="flex flex-col mb-[20rem] justify-center items-center relative">

        <div className="flex justify-center items-center">
    
          <img src={RainbowCircle} alt="Ranibow Circle" className="w-full h-fit" />

          <div className="text-content-container2 flex flex-col w-full justify-center items-center">
      
            <h1 className="text-[4rem] font-bold text-white"> Un complesso di voci per ogni <br/> aspetto della vita cittadina! </h1>
      
          </div>

        </div>

        <div className="flex w-[50%]">
        
          <h4 className="text-[1.5rem] font-medium text-center text-white"> Gli aspetti della vita cittadina sono pieni di sfaccettature, per questo motivo VOCI identifica cinque categorie d’interesse diverse: scegli la categoria di cui far parte e scopri contenuti mirati ai tuoi interessi! </h4>

        </div>

      </div>

      <div className="flex flex-col w-full gap-40">

        <div  className="flex ml-20 justify-start items-start">

          <h4 className="text-[3rem] font-bold text-white"> Scopri le cinque categorie </h4>
          
        </div>

        <div className="flex flex-col w-full">

          {categoryData.map((category) => (

            <Link key={category.name} to={category.path} className="flex justify-end items-end border-b-2" style={{ borderBottomColor: category.color }} >

              <h4 className={`text-[3rem] pr-[6rem] font-bold text-[#707070] hover:text-white transition-all uppercase`} >

                {category.name}

              </h4>

            </Link>

          ))}

        </div>

      </div>

      <LandingFooter />

    </div>

  );
}

export default Landing;
