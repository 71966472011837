import { Fragment } from 'react'
import { useState } from "react";
import { Menu, Transition } from '@headlessui/react'
import { AuthContext } from "../../context/authContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Update from "../update/Update";
import { logout } from '../../FetchCalls';


const DropdownMenu = () => {
  const [openUpdate, setOpenUpdate] = useState(false);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  
  const navigate = useNavigate();
  const logout = () => {
    navigate("/");
    setCurrentUser(null);
    
  };


  
  

  const { currentUser,setCurrentUser } = useContext(AuthContext);
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="flex flex-row justify-center items-center gap-2">
        <img src={"/upload/" + currentUser.profilePic} alt="" className="w-8 h-8 rounded-full object-cover bg-[#C0afe2]" />
        </Menu.Button>
      </div>

      
      {openUpdate ? <Update setOpenUpdate={setOpenUpdate} user={currentUser} /> : ""}
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button onClick={() => setOpenUpdate(true)}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Edit
                  </button >
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                                <button onClick={() => logout()}
                                className={classNames(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'block px-4 py-2 text-sm'
                                )}
                              >
                                Logout
                                </button >
              )}
            </Menu.Item>
          </div>
         
        </Menu.Items>
      </Transition>
      
    </Menu>
  )
};
export default DropdownMenu;