import { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../../axios";
import CitySelect from "./Components/CitySelect/CitySelect";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import VideoThumbnail from "./Components/VideoThumbnail/VideoThumbnail";
import "./View.css";
import "../map.css";

const View4Voce = ({
  changeView1,
  selectedFile,
  selectedThumbnail,
  generatedThumbnails,
}) => {
  const [city, setCity] = useState("");
  const [selectedCity, setselectedCity] = useState(false);
  const [selectedFile2, setSelectedFile2] = useState(selectedThumbnail);
  const [nameFieldValue, setNameFieldValue] = useState("");
  const [showCitySection, setShowCitySection] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleThumbnailSelect = (thumbnail) => {
    setSelectedFile2(thumbnail);
  };

  const handleNameFieldChange = (event) => {

    setNameFieldValue(event.target.value);
    setShowCitySection(!!event.target.value);

  };

  const imageMimeType = /image\/(png|jpg|jpeg)/i;

  const citiesArray = [
    { label: "Salerno", value: "Salerno", key: "1" },
    { label: "Benevento", value: "Benevento", key: "2" },
    { label: "Caserta", value: "Caserta", key: "3" },
    { label: "Napoli", value: "Napoli", key: "4" },
    { label: "Avellino", value: "Avellino", key: "5" },
  ].map((cityName, index) => ({
    id: index,
    name: cityName,

  }));

  // Dynamically create select list
  let cities = [];

  citiesArray.map((item) =>
    cities.push({
      label: item.name.label,
      value: item.name.value,
      id: item.name.key,
    })

  );

  const cityChange = (event) => {

    setselectedCity(true);
    setCity(event);

  };
  // from base64 to blob
  const dataURLtoFile = (dataurl, filename) => {

    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });

  };

  const handleSubmission = async (event) => {
    event.preventDefault();

    try {
      // if the selectedFile2 is a video then convert it to a thumbnail
      let thumbnailFile;
      if (selectedFile2) {
        thumbnailFile = dataURLtoFile(selectedFile2, "thumbnail.jpg");
      } else {
        thumbnailFile = selectedFile;
      }
      const formData = new FormData();
      // Append necessary data to the formData object
      formData.append("desc", nameFieldValue);
      formData.append(
        "city",
        (citiesArray.find((item) => item.name.value === city) || {}).name?.key
      );
      formData.append("files", selectedFile);
      formData.append("files", thumbnailFile);
    

      // Send the request
      const postResult = await makeRequest.post("/upload/voci", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (postResult.status === 200) {
        // Manually trigger a refetch of the posts
        queryClient.invalidateQueries(["allposts"]);

        console.log("File uploaded successfully.");
        // Display a success toast
        toast.success("Evento creato con successo!", {
          autoClose: 3000, // Toast will auto-close after 3 seconds
        });
        // Redirect to "/" when the response is 200
        navigate("/home");
      } else {
        console.log("File upload failed.");
        // Display an error toast
        toast.error("Non è stato possibile creare l'evento. Riprova");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Display an error toast for any other errors
      toast.error("Si è verificato un errore. Riprova più tardi.");
    }
  };

  return (

    <div className="flex flex-col w-[60rem] gap-8">

      <div className="flex flex-col">

        <button

          onClick={changeView1}
          className="flex flex-row w-fit justify-start items-center gap-4 hover:cursor-pointer"

        >
          <ArrowBackIcon />

          <p className="font-bold text-[#1C1B1F]">Crea una nuova Voce</p>
        </button>

        <div className="flex ml-10">

          <p className="font-medium text-base text-[#1C1B1F]">
            I contenuti caricati verranno presi in esame dalla nostra redazione
            e resi pubblici solo previa autorizzazione.
          </p>

        </div>

      </div>

      <div className="flex flex-row gap-4">

        <div className="flex min-w-max">

          {selectedFile.type.match(imageMimeType) ? (
            <img
              alt=""
              src={URL.createObjectURL(selectedFile)}
              className="rounded-sm w-60 h-60 object-cover"
            />
          ) : (
            <img
              alt=""
              src={selectedFile2}
              className="rounded-sm w-60 h-60 object-cover"
            />
          )}

        </div>

        <div className="flex flex-col w-full gap-8">

          <div className="flex flex-col w-full gap-2">

            <div className="flex self-start">
              <p className="font-bold text-base text-[#1C1B1F]">Titolo</p>
            </div>

            <textarea

              id="Name"
              name="Name"
              onChange={handleNameFieldChange}
              className="w-full h-12 p-2 rounded-md bg-[#E6E1E5] resize-none overflow-y-auto"

            />

          </div>
          
          {selectedFile.type.match(/video\/*/) && (
            <VideoThumbnail
              videoFile={selectedFile}
              onThumbnailSelect={handleThumbnailSelect}
            />
          )}

          <div className="flex flex-col w-full gap-2">

            <div className="flex flex-col self-start gap-6">

              <p className="font-bold text-base text-[#1C1B1F]">

                Scegli una Città

              </p>

            </div>

            <CitySelect City={selectedCity} onCitySelect={cityChange} />

          </div>

        </div>

      </div>

      <div className="flex flex-col justify-center items-center">
        <button
          onClick={handleSubmission}
          className="flex w-fit h-12 my-10 px-8 justify-center items-center rounded-3xl text-[#1C1B1F] bg-white border-2 border-[#1C1B1F] hover:text-white hover:bg-[#1C1B1F]"
        >
          <p className="text-xl font-bold">Invia richiesta di pubblicazione</p>
        </button>

      </div>

    </div>

  );
};

export default View4Voce;
