import React, { useState } from "react";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { LocalizationProvider } from "@mui/x-date-pickers";
import './DatePicker.css';
import dayjs from "dayjs";
import "dayjs/locale/it";

const DatePicker = ({ selectedDate, onDateChange }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [hovered, setHovered] = useState(false);

  const handleSelectClick = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleDateSelect = (date) => {

  const dayjsDate = dayjs(date); // Convert to Dayjs object
    onDateChange(dayjsDate);
    setModalOpen(false);
  };

  return (

    <div className="flex flex-col justify-start items-start">

      <button

        id="hoursbutton"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={handleSelectClick}
        className={`flex flex-row w-48 h-12 justify-between items-center rounded-md text-[#787579] hover:cursor-pointer ${hovered ? 'text-[#1C1B1F] bg-white' : 'text-[#1C1B1F] bg-[#E6E1E5]'}`}
      
      >

        <p className="font-medium text-base pl-2">

          {selectedDate 
            ? dayjs(selectedDate).format('D/MM/YY')  // Here's the change, using a format
            : "Seleziona"
          }

        </p>

        <CalendarMonthIcon className="rounded-r-md text-white bg-[#1C1B1F]" />

      </button>

      {modalOpen && (

        <div id="calendarmodal" className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
          
          <div className="flex flex-col justify-center items-center bg-white p-4 rounded-md">

            <LocalizationProvider dateAdapter={AdapterDayjs}
            adapterLocale="it"
            >

              <DateCalendar

                value={selectedDate && dayjs(selectedDate)} // Ensure it's a Dayjs instance
                onChange={handleDateSelect}
                renderInput={(props) => <div {...props} />}

              />

            </LocalizationProvider>

            <button onClick={handleModalClose} className="mt-2 px-4 py-2 rounded-3xl text-xl font-bold text-[#1C1B1F] bg-white border-2 border-[#1C1B1F] hover:text-white hover:bg-[#1C1B1F]">

              Chiudi

            </button>

          </div>

        </div>

      )}

    </div>

  );
};

export default DatePicker;
