import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SearchIcon from "@mui/icons-material/Search";

const SearchBar = () => {

  const [value, setValue] = useState("");
  const navigate = useNavigate();

  const handleSearch = (query) => {
    // When the user types in the input, this function will be called immediately.
    setValue(query);
    navigate(`/home/search-results?q=${query}`);
  };

  return (

    <div className="flex w-full">

      <div className="flex w-fit self-end border-b-2 border-[#E6E1E5]">

        <input

          type="text"
          placeholder="Cerca..."
          onChange={(e) => handleSearch(e.target.value)}
          className="text-sm py-1 outline-none bg-[#1C1B1F]"

        />

        <SearchIcon className="text-white" />

      </div>
      
    </div>

  );

};

export default SearchBar;