import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import GoogleIcon from "../../assets/Icons/Google-Icon.png";
import AppleIcon from "../../assets/Icons/Apple-Icon.png";

import "./login.scss";

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({email: "", password: ""});
  const [err, setErr] = useState(null);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const { login } = useContext(AuthContext);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await login(inputs);
      const { email } = inputs;


      navigate("/home");
    } catch (err) {
      setErr(err?.response?.data ?? "Errore server");
    }
  };

  const handleGoogleLogin = () => {
    window.open("https://api.voiceint.wwwnl1-ts107.a2hosted.com/auth/google", "_self");
  };

  const handleAppleLogin = async () => {
    //TODO
    alert('TODO');
  }

  return (
    <div className="loginContainer">
      <div className="loginCard">
          <h1 className="loginTitle">Login</h1>
          <p className="loginSubTitle">Nuovo utente? <Link className="loginRegisterLink" to="/register">Iscriviti</Link></p>

          <form className="loginForm">
            <input
              className="loginInput"
              type="text"
              placeholder="Email"
              name="email" //TODO: Per adesso continuiamo a usare username perchè il backend è impostato così, dobbiamo però spostarci verso il solo utilizzo delle email
              onChange={handleChange}
            />
            <input
              className="loginInput"
              type="password"
              placeholder="Password"
              name="password"
              onChange={handleChange}
            />
            <p className="loginErrorMsg">{err && err}</p>
            
            {/* <label className="loginRememberMeContainer">Ricordami
              <input type="checkbox" name="rememberMe" className="loginRememberMeCheckBox"/>
              <span className="loginRememberMeCheckmark" />
            </label> */}

            <button className="loginBtn" onClick={handleLogin}>ACCEDI</button>
          </form>

          <p className="loginDividerText">oppure</p>

          {/* <button className="alternativeLoginBtn" onClick={handleAppleLogin} disabled={isLoading}>
            <img className="alternativeLoginIcon" src={AppleIcon} alt="" />
            ACCEDI CON APPLE
          </button> */}

          <button className="alternativeLoginBtn" onClick={handleGoogleLogin} disabled={isLoading}>
            <img className="alternativeLoginIcon" src={GoogleIcon} alt="" />
            ACCEDI CON GOOGLE
          </button>
      </div>
    </div>
  );
};

export default Login;
