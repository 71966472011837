import { useEffect, useState } from 'react';
import VerticalPost from "../post/VerticalPost";
import "./posts.scss";
import GridPost from '../post/GridPost';
import GridIconWhite from '../../assets/Icons/Grid-View-Icon-White.png';
import GridIconAntracite from '../../assets/Icons/Grid-View-Icon-Antracite.png';
import VerticalIconWhite from '../../assets/Icons/Vertical-View-Icon-White.png';
import VerticalIconAntracite from '../../assets/Icons/Vertical-View-Icon-Antracite.png';

const Posts = ( allPosts ) => {
  const [cityFilter, setCityFilter] = useState(null);
  const [isGrid, setIsGrid] = useState(false);

  const toggleView = () => {
    setIsGrid((prevIsGrid) => !prevIsGrid);
  };

  // Chunk the data array into groups of three posts per row
  const splicedDataGrid = Array.from(
    { length: Math.ceil(allPosts.posts.length / 3) },
    (_, index) => allPosts.posts.slice(index * 3, index * 3 + 3)
  );

  const handleFilterChangeValue = (e) => {
    if (e.target.value == "") return;

    if(e.target.value === "CANCEL") {
      e.target.value = "";
      setCityFilter(null);
    } else {
      setCityFilter(e.target.value);
    }
  }

  return (
    <div className="posts">

      <div className="flex justify-between">

        {/* //TODO: Sostituire il select sotto con un "select" fatto a mano con divs (o usando mui), per avere completo controllo dello styling. Attualmente non è fisicamente possibile visto che il dropdown è gestito dall'OS */}
        <select className='cityFilterSelect' name="city" id="city-filter" onChange={handleFilterChangeValue}>
          <option value="" selected disabled hidden>Città</option>
          <option value="CANCEL" >Non Filtrare</option>
          <option value="Salerno"><a>Salerno</a></option>
          <option value="Benevento"><a>Benevento</a></option>
          <option value="Napoli"><a>Napoli</a></option>
          <option value="Caserta"><a>Caserta</a></option>
          <option value="Avellino"><a>Avellino</a></option>
        </select>

        <div className='flex flex-row w-fit rounded-md border-2 border-white'>

          <button
            onClick={() => setIsGrid(true)}
            className={`flex p-2 text-center rounded-l-sm ${
              isGrid ? "bg-white" : "bg-[#1C1B1F]"
            }`}
          >

            {isGrid ? <img src={GridIconAntracite} alt="Grid Antracite Icon" className='h-6 w-6'/> : <img src={GridIconWhite} alt="Grid White Icon" className='h-6 w-6'/> }

          </button>
        
        <button
          onClick={() => setIsGrid(false)}
          className={`flex p-2 text-center rounded-r-sm ${
            isGrid ? "bg-[#1C1B1F]" : "bg-white"
          }`}
        >

          {isGrid ? <img src={VerticalIconWhite} alt="Vertical White Icon" className='h-6 w-6' /> : <img src={VerticalIconAntracite} alt="Vertical Antracite Icon" className='h-6 w-6' /> }
        
        </button>

        </div>

      </div>

      {isGrid ? (
        // Render posts in grid view
      <div>
      {splicedDataGrid.map((row, rowIndex) => (
        <div
            key={rowIndex}
            className='squarePostsGrid'
            //className={`grid grid-cols-3 mb-4 gap-4 mt-8`} //
          >
            {row.filter(post => (cityFilter == null || post.city == cityFilter)).map((post) => (
              <GridPost post={post} postId={post.id} />
            ))}
          </div>
        ))}
      </div>
      ) : (
        // Render posts in vertical view
        <div className='flex flex-col gap-4 mt-12'>
          {allPosts.posts.filter(post => (cityFilter == null || post.city == cityFilter)).map((post, index) => (
            <div key={post.id} className={`mb-4 ${index !== 0 ? 'border-t-2 pt-12' : ''}`}>
              <VerticalPost post={post} postId={post.id} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Posts;