import { useNavigate,Form } from 'react-router-dom';
import Image from "../../assets/img.png";

import { useContext, useState } from "react";
import { AuthContext } from "../../context/authContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import classes from './NewStory.module.css';
import Modal from '../modal/Modal';

const NewStory = () => {
  const [file, setFile] = useState(null);
  const [desc, setDesc] = useState("");
  const navigate = useNavigate()
  const upload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("desc", desc);
      const res = await makeRequest.post("/upload/story", formData);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const { currentUser } = useContext(AuthContext);


  const handleClick = async (e) => {
    e.preventDefault();
    let imgUrl = "";
    if (file) {imgUrl = await upload();
      //window.location.replace("http://localhost:3000/");
    }
    else{}
    setDesc("");
    setFile(null);
  };



  return (
    <Modal>
      <Form  className={classes.form}>
      <div className="share">
      <div className="container">
        <div className="top">
          <div className="left">
            <input
              type="text" required
              placeholder={`Can you hear my Voci?`}
              onChange={(e) => setDesc(e.target.value)}
              value={desc}
            />
          </div>
          <div className="right">
            {file && (
              <img className="file" alt=""  src={URL.createObjectURL(file)} />
            )}
          </div>
        </div>
        <hr />
        <div className="bottom">
          <div className="left">
            <input
              type="file"
              id="file"
              required
              style={{ display: "none" }}
              onChange={(e) => setFile(e.target.files[0])}
            />
            <label htmlFor="file">
              <div className="item">
                <img src={Image} alt="" />
                <span>Add Image</span>
              </div>
            </label>
            
          </div>
          <div className="right">
            <button onClick={handleClick}>Share</button>
          </div>
        </div>
      </div>
    </div>
      </Form>
    </Modal>
  );
}

export default NewStory;