import React, { useState, useEffect } from "react";

const VideoThumbnail = ({ videoFile, onThumbnailSelect }) => {
  const [thumbnails, setThumbnails] = useState([]);
  const [selectedThumbnail, setSelectedThumbnail] = useState(null);

  useEffect(() => {
    generateThumbnails(videoFile);
  }, [videoFile]);

  const generateThumbnails = async (videoFile) => {
    const video = document.createElement("video");
    video.preload = "metadata";
    video.src = URL.createObjectURL(videoFile);
  
    await new Promise((resolve) => {
      video.onloadedmetadata = resolve;
    });
  
    const numThumbnails = 4; // Number of thumbnails to generate
    const thumbnailWidth = 160;
    const thumbnailHeight = 90;
    const interval = video.duration / numThumbnails;
  
    const thumbnailArray = [];
    for (let i = 0; i < numThumbnails; i++) {
      const canvas = document.createElement("canvas");
      canvas.width = thumbnailWidth;
      canvas.height = thumbnailHeight;
      const ctx = canvas.getContext("2d");
      video.currentTime = (i + 1) * interval; // Adjusted currentTime
  
      await new Promise((resolve) => {
        video.onseeked = async () => {
          ctx.drawImage(video, 0, 0, thumbnailWidth, thumbnailHeight);
          const thumbnailDataUrl = canvas.toDataURL("image/jpeg");
          console.log(`Thumbnail ${i} generated.`);
          thumbnailArray.push(thumbnailDataUrl);
          resolve();
        };
      });
    }
  
    console.log("Generated Thumbnails:", thumbnailArray);
    setThumbnails(thumbnailArray);
  };

  const handleThumbnailClick = (thumbnail) => {
    setSelectedThumbnail(thumbnail);
    if (onThumbnailSelect) onThumbnailSelect(thumbnail);
    // You can perform further actions here, such as saving the selected thumbnail.
  };

  return (

    <div className="flex flex-col justify-center items-center gap-2">

      <div className="flex w-full justify-start">
      
        <p className=" font-bold text-[#1C1B1F] text-base">Copertina</p>
      
      </div>

      <div className="thumbnails-container flex flex-row w-full gap-2 justify-between items-center">

        {thumbnails.map((thumbnail, index) => (

          <img

            key={index}
            src={thumbnail}
            alt={`Thumbnail ${index}`}
            className={`rounded-xl border-2 border-[#E6E1E5] hover:cursor-pointer ${thumbnail === selectedThumbnail ? "ring-2 ring-[#1C1B1F]" : "hover:ring-2 hover:ring-[#1C1B1F]"}`}
            onClick={() => handleThumbnailClick(thumbnail)}

          />

        ))}

      </div>

    </div>

  );
};

export default VideoThumbnail;
