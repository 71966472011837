import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './CitySelect.css';

const CitySelect = ({ selectedCity, onCitySelect }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [hovered, setHovered] = useState(false);
  const [city, setCity] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCitySelect = (city) => {
    onCitySelect(city);
    setCity(city);
    handleClose();
  };

  const citiesArray = [
    { label: "Salerno", value: "Salerno", key: "1" },
    { label: "Benevento", value: "Benevento", key: "2" },
    { label: "Caserta", value: "Caserta", key: "3" },
    { label: "Napoli", value: "Napoli", key: "4" },
    { label: "Avellino", value: "Avellino", key: "5" },
  ].map((cityName, index) => ({
    id: index,
    name: cityName,
  }));

  // Dynamically create select list
  let cities = [];
  citiesArray.map((item) =>
    cities.push({
      label: item.name.label,
      value: item.name.value,
      id: item.name.key,
    })
  );

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (

    <div className="flex flex-col justify-start items-start">

      <button

        id="citybutton"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={handleClick}
        className={`flex flex-row w-48 h-12 justify-between items-center rounded-md text-[#787579] hover:cursor-pointer ${hovered ? 'text-white bg-[#1C1B1F]' : 'text-[#1C1B1F] bg-[#E6E1E5]'}`}
      
      >

        <p className="font-medium text-base pl-2">

          {city ? city : "Scegli una Città"}

        </p>

        <ExpandMoreIcon className="rounded-r-md text-white bg-[#1C1B1F]" />

      </button>

      <Popover

        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}

      >        

        <div className="flex flex-col w-36 py-4 gap-2 rounded-md bg-[#E6E1E5]">
      
          {cities.map((city) => (
          
            <div key={city.value} className="flex flex-col gap-4">
             
              <button onClick={() => handleCitySelect(city?.value)} className="flex w-full pl-2 justify-start items-center font-medium text-[#1C1B1F] bg-[#E6E1E5] hover:text-white hover:bg-[#1C1B1F]" >
                
                {city.label}

              </button>

            </div>

          ))}

        </div>

      </Popover>

    </div>

  );
};

export default CitySelect;
