import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import RainbowBottom from '../../../../assets/Landing/Rainbow-Bottom.png';
import ambienteBottom from '../../../../assets/Landing/Bottom-Ambiente.png';
import centriBottom from '../../../../assets/Landing/Bottom-Centri.png';
import mobilitaBottom from '../../../../assets/Landing/Bottom-Mobilità.png';
import talentiBottom from '../../../../assets/Landing/Rainbow-Bottom.png';


const bottomimgData = [
  {
    bottomImg: RainbowBottom,
    path: "/",
  },
  {
    bottomImg: ambienteBottom,
    path: "/home/ambiente",
  },
  {
    bottomImg: centriBottom,
    path: "/home/centri-culturali",
  },
  {
    bottomImg: mobilitaBottom,
    path: "/home/mobilita",
  },
  {
    bottomImg: talentiBottom,
    path: "/home/servizi-per-la-comunita",
  },
  {
    bottomImg: talentiBottom,
    path: "/home/talenti",
  },
];

function LandingFooter() {
  const navigate = useNavigate();
  const location = useLocation();

  const goToRegister = () => {
    navigate('/register');
  };

  const goToLogin = () => {
    navigate('/login');
  };

  // Find the matching image based on the current route
  const currentPath = location.pathname;
  const matchingImage = bottomimgData.find(item => item.path === currentPath);

  // Check if matchingImage is defined and has a bottomImg property
  const bottomImg = matchingImage ? matchingImage.bottomImg : RainbowBottom;


  return (

    <div className="flex flex-col w-full justify-center items-center">

      <div className="flex flex-col mt-[10rem] justify-center items-center gap-6 z-10">
              
        <h1 className="text-[5rem] font-bold text-white"> Fai sentire la tua voce! </h1>

        <div className="flex w-[40%]">

          <h4 className="text-[1.2rem] font-medium text-center text-white"> Promuovendo le attività locali speriamo di creare un senso più forte di comunità e orgoglio cittadino. Unisciti a VOCI e inizia a promuovere il tuo talento a un pubblico più ampio. Libera la tua voce ed entra a far parte della comunità del tuo territorio! </h4>

        </div>

        <button onClick={goToRegister} className="flex w-fit h-12 my-10 px-8 justify-center items-center rounded-3xl text-[#1C1B1F] bg-white border-2 border-[#1C1B1F] hover:text-white hover:bg-[#1C1B1F] hover:border-white">
      
          <p className="text-xl font-bold"> Iscriviti </p>
  
        </button>
        <button onClick={goToLogin} className="flex w-fit h-12 my-10 px-8 justify-center items-center rounded-3xl text-[#1C1B1F] bg-white border-2 border-[#1C1B1F] hover:text-white hover:bg-[#1C1B1F] hover:border-white">
      
          <p className="text-xl font-bold"> Accedi </p>

        </button>
        
      </div>

      <div className="flex w-[50%] -mt-[22rem]">

        <img src={matchingImage.bottomImg} alt="Rainbow Bottom" className="w-full h-full" />

      </div>

      <div className="flex flex-row w-[95%] h-12 -mt-12 gap-8 justify-start items-center bg-opacity-0">

        <button className="flex  text-[#787579] hover:text-white transition-all">

          <p className="text-base"> Privacy Policy </p>

        </button>

        <button className="flex  text-[#787579] hover:text-white transition-all">

          <p className="text-base"> Terms of Service </p>

        </button>

      </div>


    </div>

  );
}

export default LandingFooter;
