import { useEffect, useState } from "react";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { modifyUser } from "../../FetchCalls";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./update.scss";

const Update = ({ setOpenUpdate, user, onUpdateUser }) => {
  //TODO: Rimuovere valori di default per testing da values
    const [values, setValues] = useState({
      username: user.username, //TODO: Continuiamo a usare username perchè backend è ancora così, miriamo a rimuoverlo in favore dell'email
      name: user.name,
      surname: user.surname,
      city: user.city,
      occupation: user.occupation,
      category: user.category,
      bio: user.bio,
      id: user.id
    });

    //TODO: Io userei Formik per la gestione dei form (facilita la modifica e la validazione)

    const handleChange = (e) => {
      setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };    

    const handleSubmit = async (e) => {
      e.preventDefault();
    
      try {
        // Send a POST request to update the user profile with the updated values
        await modifyUser(values);
    
        // Show a success toast message
        toast.success('Il profilo è stato correttamente aggiornato!', {
          autoClose: 3000,
        });
    
        setOpenUpdate(false); // Close the update modal or perform any other action as needed
  
        // Call the callback function to trigger a profile data update
        if (onUpdateUser !=null)
          onUpdateUser();
      } catch (error) {
        // Show an error toast message
        toast.error('C’è stato un errore, riprova.');
      }
    };   

  const resizeTextarea = (e) => {
    if (e) {
      e.target.style.height = "";
      e.target.style.height = e.target.scrollHeight + "px";
    } else {
      document.getElementById("bio-textarea").style.height = "";
      document.getElementById("bio-textarea").style.height = document.getElementById("bio-textarea").scrollHeight + "px";
    }
  }

  useEffect(() => {
    resizeTextarea(null);
  });

  return (
    <div className="update">
      <div className="clickableBg" onClick={() => setOpenUpdate(false)} />

      <div className="mainDiv">
        <div className="overlayHeader">
          <h2>Modifica profilo</h2>

          <button className="closeBtn" onClick={() => setOpenUpdate(false)}><CloseRoundedIcon /></button>
        </div>
        {/* TODO: Questo bottone potrebbe probabilmente essere un'icona cliccabile. Userei React-Icons, non l'ho inserita, neanche all'interno del bottone, per mantenere le modifiche contenute e per non imporre un metodo lavorativo. */}
        
        <picture className='pfp'>
          <source media="(min-width:0px)" srcset={"/upload/"+user?.profilePic} />
          <img src={"/upload/"+user?.profilePic} alt="" />
        </picture>

        <form onSubmit={handleSubmit}>
          <div className='formField'>
            <label>Username</label>
            <input
              type="text"
              value={values.username}
              name="username"
              onChange={handleChange}
            />
          </div>
          <div className='formField'>
            <label>Nome</label>
            <input
              type="text"
              value={values.name}
              name="name"
              onChange={handleChange}
            />
          </div>
          <div className='formField'>
            <label>Cognome</label>
            <input
              type="text"
              value={values.surname}
              name="surname"
              onChange={handleChange}
            />
          </div>
          <div className='formField'>
            <label>Città di riferimento</label>
            {/* TODO: Questo input dovrà probabilmente diventare un Select, con le opzioni prese da server o API esterne come Google */}
            <input
              type="text"
              value={values.city}
              name="city"
              onChange={handleChange}
            />
          </div>
          <div className='formField'>
            <label>Occupazione</label>
            <input
              type="text"
              value={values.occupation}
              name="occupation"
              onChange={handleChange}
            />
          </div>
          <div className='formField'>
            <label>Categoria</label>
            <input
              type="text"
              value={values.category}
              name="category"
              onChange={handleChange}
            />
          </div>
          <div className='formField'>
            <label>Biografia</label>
            <textarea
              id="bio-textarea"
              type="text"
              value={values.bio}
              name="bio"
              onChange={handleChange}
              rows="4"
              onInput={(e) => resizeTextarea(e)}
            />
          </div>

          <div className="buttonsDiv">
            <button className="secondary" type="button" onClick={() => setOpenUpdate(false)}>ANNULLA</button>
            <button className="primary" type="submit">SALVA</button>
          </div>
        </form>
      </div>
      
    </div>
  )
}

export default Update;