import React from "react";
import { useQuery } from '@tanstack/react-query';
import CarouselPlaceholder from "../../assets/Meet&greet-Carousel-Placeholder.png";
import PurpleHexagon from "../../assets/Hexagon-Purple.svg";
import PurpleStar from "../../assets/Star-Purple.svg";
import AmbienteImg from "../../assets/Category-Ambiente.png";
import CentriImg from "../../assets/Category-Centri-Culturali.png";
import MobilityImg from "../../assets/Category-Mobilità.png";
import ServiziImg from "../../assets/Category-Servizi-alla-Comunità.png";
import { fetchAllDashboardPosts, getUserId } from "../../FetchCalls";
import "./Explore.css";

const categoriesButtons = [
  {
    id: 1,
    title: "Ambiente",
    imageUrl: AmbienteImg,
    path: "/category1",
    shouldWrap: false,
  },
  {
    id: 2,
    title: "Centri\n Culturali",
    imageUrl: CentriImg,
    path: "/category2",
    shouldWrap: true,
  },
  {
    id: 3,
    title: "Mobilità",
    imageUrl: MobilityImg,
    path: "/category3",
    shouldWrap: false,
  },
  {
    id: 4,
    title: "Servizi alla\n Comunità",
    imageUrl: ServiziImg,
    path: "/category4",
    shouldWrap: true,
  },
];

const Explore = () => {

  const userId = getUserId();
  const { data: posts, isSuccess, isLoading, refetch } = useQuery(["allposts"], () => fetchAllDashboardPosts(), {});

  const handleCategoryClick = (path) => {
    console.log(`Clicked on category with path: ${path}`);
  };

  return (
    
    <div className="flex flex-col w-full py-12 justify-start items-center gap-[10rem]">
      
      <h1 className="text-white font-bold text-center"> I prossimi Meet&Greet <br/> vicino a te </h1>

      <div className="flex flex-col justify-center items-center gap-20">

        <img src={CarouselPlaceholder} alt="" className="flex w-[800px]"/>

        <button className='flex w-80 h-16 justify-center items-center font-bold rounded-full text-white border-2 border-white bg-[#1C1B1F] hover:text-[#1C1B1F] hover:border-white hover:bg-white'>

          <p> Mostra Tutti </p>

        </button>

      </div>

      <>

        <div className="flex relative">

          <img src={PurpleHexagon} alt="" className="flex w-[30rem]" />

          <p className="absolute inset-0 flex items-center justify-center text-[2.5rem] font-bold text-white text-center">

            Di più dalla tua <br /> categoria

          </p>

        </div>

        <div className="grid grid-cols-3 mt-[-18rem] gap-2">

          {posts?.map((post) => (

            <div key={post.id} className="card">

              <img
              
                src={`data:${post.thumbnail.type};base64,${post.thumbnail.content}`}
                alt={post.title}
                className="w-80 h-80 hover:cursor-pointer object-cover hover:ring-2 hover:ring-white rounded-sm scale-100 hover:brightness-50 transition-all"

              />

              <h2 className="card-title hover:cursor-pointer"> {post.title} </h2>

            </div>

          ))}

        </div>

        <button className='flex w-80 h-16 -mt-24 justify-center items-center font-bold rounded-full text-white border-2 border-white bg-[#1C1B1F] hover:text-[#1C1B1F] hover:border-white hover:bg-white'>

          <p> Mostra Tutti </p>

        </button>

      </>

      <div className="flex flex-col justify-center items-center">

        <p className="flex items-center justify-center text-[2.5rem] font-bold text-white text-center">

          I più popolari <br/> della tua categoria

        </p>

        <img src={PurpleStar} alt="" className="flex w-[800px]"/>

      </div>

      <div className="flex flex-col w-[800px] justify-center items-start gap-20">

        <h1 className="flex items-center justify-center text-left font-bold text-white">

          Esplora le altre <br/> categorie!

        </h1>

        <div className="grid grid-cols-2 gap-x-[8rem] gap-y-8">

          {categoriesButtons.map((card) => (

            <div key={card.id} className="flex flex-row justify-start items-center hover:cursor-pointer">

              <img

                src={card.imageUrl}
                alt={card.title}
                className="w-40 h-40 object-cover"
                onClick={() => handleCategoryClick(card.path)}

              />

              <p className={`text-white font-bold uppercase -ml-12 ${card.shouldWrap ? 'multi-line-text' : ''}`}> {card.title} </p>

            </div>

          ))}

        </div>

      </div>

    </div>
  
  );
};

export default Explore;
