import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../context/authContext";
import NotificationsIcon from '@mui/icons-material/Notifications';
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import "./navbar.scss";
import DropdownMenu from "./DropdownMenu.js";
import { Tooltip } from "@mui/material";
import SearchBar from "../searchbar/SearchBar";

const Navbar = ({ setSearchQuery }) => {

  const { currentUser } = useContext(AuthContext);

  const navigate = useNavigate();

  const goToHome = () => {
    navigate("/home");
  };

  const goToChat = () => {
    navigate("/home/chat");
  };

  const goToProfile = () => {
    navigate(`/home/profile/${currentUser.id}`);
  };

  const handleSearch = (e) => {
    const inputValue = e.target.value;
    setSearchQuery(inputValue); // Update the searchQuery state
    // ... rest of your code
  };

  const goToSearchResults = () => {
    navigate("/home/search-results");
  };

  return (

    <div className="navbar">

      <div className="left">

        <button onClick={goToHome}>

          <span> Voci </span>

        </button>

      </div>

      <div className="right">

        <div className="flex">

          {location.pathname === "/home/search-results" ? (

            <SearchBar setSearchQuery={setSearchQuery} />

          ) : (

            <Tooltip title="Cerca">

              <button onClick={goToSearchResults} className="search-button">

                <SearchOutlinedIcon />

              </button>

            </Tooltip>

          )}

        </div>

        <div className="flex">

          <Tooltip title="Messaggi">

            <button onClick={goToChat} >

              <EmailOutlinedIcon />

            </button>

          </Tooltip>

        </div>

        {/* TODO: Riattivare icona notifica quando verranno implementate

        <div className="flex">

          <Tooltip title="Notifiche">

            <NotificationsIcon />

          </Tooltip>

        </div> */}

        <div className="flex">

          <Tooltip title="Profilo">

            {/* <button onClick={goToProfile} className="flex flex-row justify-center items-center gap-2" >

              

            </button> */}
            <DropdownMenu />

          </Tooltip>

        </div>

      </div>

    </div>

  );
};

export default Navbar;
