import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllUsers } from '../../FetchCalls';
import PlaceIcon from '@mui/icons-material/Place';
import "./SearchPage.css";

const SearchPage = () => {
  const [results, setResults] = useState([]);
  const location = useLocation(); // Import useLocation and get the location
  const navigate = useNavigate(); // For redirecting to user profile
  const query = new URLSearchParams(location.search).get('q');

  // other useEffect if query changes go search inside the results for
  // any reason that have the query inside the username
    useEffect(() => {
      const fetchData = async () => {
        try {
          const data = await getAllUsers();
          const usersArray = Object.values(data);   
          if (query && query.trim() !== '') {  // If the query is non-empty, filter the results
            const filteredResults = usersArray.filter(user => user.username.includes(query));
            setResults(filteredResults);
          } else {  // Otherwise, set all users to results
            setResults(usersArray);
          }
  
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      fetchData();
  }, [query]); // This effect runs when the component mounts and whenever query changes
   // Empty dependencies array means the effect will only run once, similar to componentDidMount


  const openUserProfile = (userId) => {
    navigate(`/home/profile/${userId}`);
  }


  return (

    <div className='flex flex-col w-full py-12 justify-start items-center gap-8'>

      <div className='flex flex-row w-full justify-center items-center mb-8'>

        <button className='flex w-40 h-12 justify-center items-center font-bold rounded-full text-[#1C1B1F] border-2 border-white bg-white'>

          <p> Utenti </p>

        </button>

      </div>

      <div className="flex flex-col w-full justify-center items-center search-results">

        <div className="grid grid-cols-4 w-full gap-y-20">

          {results.length > 0 && results.map((user) => (

            <div key={user.id} className="flex flex-col justify-between items-center gap-4">

              <img

                src={`/upload/${user.profilePic}`}
                alt=""
                className="flex w-48 h-48 rounded-full object-cover hover:ring-2 hover:ring-white hover:cursor-pointer"
                onClick={() => openUserProfile(user.id)}

              />

              <div className="flex flex-col justify-center items-center gap-2">

                <p className="text-white text-md font-bold capitalize">{user.name} {user.surname}</p>          

                <div className='flex flex-row justify-center items-center gap-4'>

                  <p className="text-white text-sm uppercase">{user.occupation || "occupazione"}</p>
                 
                  <div className="divider" />

                  <div className='flex flex-row justify-center items-center gap-1'>
                  
                    <PlaceIcon className="text-white" />
                    
                    <p className="text-white text-sm font-medium capitalize">{user.city || "città"}</p>

                  </div>

                </div>                

              </div>

            </div>

          ))}

        </div>

      </div>

    </div>

  );
};

export default SearchPage;
