import { useContext, useState, useEffect } from "react";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import { createBrowserRouter, RouterProvider, Outlet, Navigate } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import LeftBar from "./components/leftBar/LeftBar";
import NewContentHome from "./components/createContent/NewContentHome";
import NewContent from "./components/createContent/NewContent";
import NewStory from "./components/newStory/NewStory";
import ChatPage from "./components/chatpage/ChatPage";
import SearchBar from "./components/searchbar/SearchBar";
import Home from "./pages/home/Home";
import Profile from "./pages/profile/Profile";
import Explore from "./pages/explore/Explore";
import { DarkModeContext } from "./context/darkModeContext";
import { AuthContext } from "./context/authContext";
import Cookies from 'js-cookie';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import "./style.scss";
import "./style.css";
import 'react-toastify/dist/ReactToastify.css';
import FloatingNavIcon from "./components/mobileNav/floatingNavIcon";
import MobileNav from "./components/mobileNav/mobileNav";
import SearchPage from "./pages/search/SearchPage";
import Totem from "./pages/totem/Totem";
import Landing from "./pages/landing/Landing";
import CityPage from "./pages/landing/Components/CityPages/CityPage";
import CategoryPage from "./pages/landing/Components/CategoryPage/CategoryPage";
import Loading from "./pages/loading/Loading";

function App() {
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const { darkMode } = useContext(DarkModeContext);
  const [searchQuery, setSearchQuery] = useState('');
  const queryClient = new QueryClient();

  // useEffect(() => {
  //   const getUser = () => {
  //     fetch("https://api.voiceint.wwwnl1-ts107.a2hosted.com/auth/google/success", {
  //       method: "GET",
  //       credentials: "include",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         "Access-Control-Allow-Credentials": true,
  //       },
  //     })
  //       .then((response) => {
  //         console.log(response);
  //         if (response.status === 200) return response.json();
  //         throw new Error("authentication has failed!");
  //       })
  //       .then((resObject) => {
  //         setCurrentUser(resObject.user);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };
  //   getUser();
  // }, [])


  // useEffect(() => {
  //   if (currentUser != null && (window.location.href.endsWith("/login") || window.location.href.endsWith("/loading"))) {
  //     window.location.replace("https://voiceint.wwwnl1-ts107.a2hosted.com/home");
  //   }
  // }, [currentUser])

  // Had to stop using the JS method to show mobileNav due to the main page's content reloading when toggling the mobileNav
  // const [showMobileNav, setShowMobileNav] = useState(false);

  // const ToggleMobileNav = () => {
  //   //Stop scrolling on rest of the app
  //   document.body.style.overflow = !showMobileNav ? "hidden" : "auto"; //Generic
  //   document.body.style.touchAction = !showMobileNav ? "none" : "auto"; //For mobile devices

  //   setShowMobileNav(!showMobileNav);
  // }

  const ToggleMobileNav = () => {
    const mobileNav = document.getElementById("mobile-nav");
    const floatingNavIcon = document.getElementById("floating-nav-icon");

    if (mobileNav.classList.contains('hidden')) {
        floatingNavIcon.classList.add("inverted");
        mobileNav.classList.remove('hidden')

        //Stop scrolling on rest of the app
        document.body.style.overflow = "hidden"; //Generic
        document.body.style.touchAction = "none"; //For mobile devices

    } else {
      floatingNavIcon.classList.remove("inverted");
      mobileNav.classList.add('hidden');

      //Resume scrolling on rest of the app
      document.body.style.overflow = "auto"; //Generic
      document.body.style.touchAction = "auto"; //For mobile devices
    }
  }


  const Layout = () => {
    return (
      <QueryClientProvider client={queryClient}>
        <div className={`theme-${darkMode ? "dark" : "dark"}`}>
          <Navbar setSearchQuery={setSearchQuery} />
          <div style={{ display: "flex" }}>
           <LeftBar />
           <FloatingNavIcon ToggleMobileNav={ToggleMobileNav}/>
           {/* {showMobileNav && */}
            <MobileNav ToggleMobileNav={ToggleMobileNav}/>
            {/* } */}
            <div style={{ flex: 5 }}>
              <Outlet />
            </div>
            {/* <RightBar />  */}
          </div>
        </div>
      </QueryClientProvider>
    );
  };

  const ProtectedRoute = ({ children }) => {
    // const isCookieSet = Cookies.get('accessToken') !== undefined;
   
    // if(!isCookieSet){
    //   return <Navigate to="/login" />;
    // }
    if (!currentUser) {
      return <Navigate to="/login" />;
    }

    return children;
  };

  const router = createBrowserRouter([{
      path: "/",
      element: <Landing />,
    },
    {
      path: "/home",
      element: <Landing />,
    },
    {
      path: "/home",
      element: (
        <ProtectedRoute>
          <Layout />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "/home",
          element: <Home />,
          children : [
            {
              path: "/home/create-story",
              element: <NewStory />,
            },
            {
              path: "/home/create-content-home",
              element: <NewContentHome />,
            },
            {
              path: "/home/create-content-new",
              element: <NewContent />,
            },
            {
              path: "/home/chat",

              element: <ChatPage  />
              // children: [{
              //   path: "/chat/search",
              //   element: <SearchBar />,
              // }],
            }
        ]
        },
        {
          path: "/home/explore",
          element: <Explore />,
        },
        {
          path: "/home/profile/:id",
          element: <Profile />,
        },
        {
          path: "/home/search-results",
          element: <SearchPage />,
        },
        {
          path: "/home/totem",
          element: <Totem />,
        },
      ],
    },
    {
      path: "/loading",
      element: <Loading />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/city/:cityName",
      element: <CityPage />,
    },
    {
      path: "/home/ambiente",
      element: <CategoryPage />,
    },
    {
      path: "/home/centri-culturali",
      element: <CategoryPage />,
    },
    {
      path: "/home/mobilita",
      element: <CategoryPage />,
    },
    {
      path: "/home/servizi-per-la-comunita",
      element: <CategoryPage />,
    },
    {
      path: "/home/talenti",
      element: <CategoryPage />,
    },
    {
      path: "/privacy-policy",
      element: <CityPage />,
    },
    {
      path: "/terms",
      element: <CityPage />,
    },
  ]);

  return (
    <div>
      <ToastContainer />
      <RouterProvider router={router}>
        <Layout />
      </RouterProvider>
    </div>
  );
}

export default App;
