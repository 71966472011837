import { useContext } from "react";
import { AuthContext } from "../../context/authContext";
import { useQuery } from "@tanstack/react-query";
import { getPostInfo, handleLike, handleDislike } from "../../FetchCalls";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import "./comments.scss";

const Comments = ({ postId }) => {
  // Access the AuthContext to get the current user
  const authContext = useContext(AuthContext);

  const { isLoading, isError, data, isSuccess } = useQuery(
    ["comments", postId],
    async () => {
      const result = await getPostInfo(postId);
      return result;
    },
    {
      enabled: !!postId,
    }
  );

  if (!data || !data[postId]?.commentsMap) {
    return <div className="noCommentsDiv">Nessun commento disponibile</div>;
  }

  return (
    
    <div className="comments w-full">

      {Object.values(data[postId]?.commentsMap).map((comment) => {
        
        const likesMap = comment.likesMap;

        const currentUserLikes = Object.values(likesMap).filter((like) => like.likesUserId === authContext.currentUser?.id);

        return (

          <div className="comment justify-center items-center" key={comment.commentUserId}>
            
            <img src={`data:${comment.commentUserProfilePic.type};base64,${comment.commentUserProfilePic.content}`} alt={comment.commentUsername} />

          <div className="info">

            <p className="text-base font-bold text-[#1C1B1F]">{comment.commentUsername}</p>

            <p className="text-base text-[#1C1B1F]">{comment.text}</p>

          </div>

            <span className="likes gap-2">

              <h6>{Object.keys(comment.likesMap).length}</h6>

              {currentUserLikes.length > 0 ? (

                <button onClick={() => { handleDislike(comment.commentId); }}>

                  <ThumbDownIcon className="likeIcon" />

                </button>

              ) : (

                <button onClick={() => { handleLike(comment.commentId); }}>

                  <ThumbUpIcon className="likeIcon" />

                </button>

              )}

            </span>

          </div>

        );

      })}

    </div>

  );
};


export default Comments;
