import { useContext, useState, useEffect, useRef } from "react";
import { useNavigate, Form } from 'react-router-dom';
import { AuthContext } from "../../context/authContext";
import classes from './NewContent.module.css';
import View1 from "./Views/View1";
import View2 from "./Views/View2";
import View3 from "./Views/View3";
import View4Meet from "./Views/View4Meet";
import View4Voce from "./Views/View4Voce";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NewContentHome = (mode) => {

  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [menu, setMenu] = useState("");
  const [view, setView] = useState(1);
  const [selectedFile, setSelectedFile] = useState(null);
  const [generatedThumbnails, setGeneratedThumbnails] = useState([]);
  const [selectedThumbnail, setSelectedThumbnail] = useState(null);
  function changeView(view) {

    if (view === "voce") {
      setView(2);
      setMenu("Voce");

    }
    else if (view === "meet") {
      setView(3);
      setMenu("Meet&Greet");
    }
    else if (view === "home") {
      setView(1);
      setMenu("");
    }
    else if (view === "file") {
      setView(4);
    }

  }

  function closeHandler() {
    navigate('..');
  }

  const changeHandler = (event) => {
    const selectedFile = event.target.files[0];
  
    if (selectedFile) {
      if (selectedFile.type.startsWith("video/")) {
        // Check if it's a video file
        const maxSize = 10 * 1024 * 1024; // 10MB in bytes
        if (selectedFile.size <= maxSize) {
          setSelectedFile(selectedFile);
        } else {
          // Video file size exceeds the limit
          toast.error("Video file size should be less than 10MB.", {
            autoClose: 5000, // Toast will auto-close after 5 seconds
          });
          event.target.value = null; // Clear the file input
          setSelectedFile(null);
        }
      } else {
        setSelectedFile(selectedFile);
      }
    }
  };
  

  const updateGeneratedThumbnails = (thumbnails) => {
    console.log(thumbnails);
    console.log("thumbnails from view2");
    setGeneratedThumbnails(thumbnails);
  };

  const updateSelctedThumbnails = (thumbnails) => {
    console.log(thumbnails);
    console.log("thumbnails from view2");

    setSelectedThumbnail(thumbnails);
  }

  return (

    <>

      <div className={classes.backdrop} onClick={closeHandler} />

      <dialog open className="flex w-fit rounded-2xl justify-center items-center mx-auto z-10">

        <div className="flex py-4 px-6 rounded-2xl justify-center items-center">

          {view == 1 ?

            <View1
              
              menu={menu}
              changeView1={() => changeView("voce")}
              changeView2={() => changeView("meet")}
              
            />

            : view == 2 ?

              <View2 
              
              selectedFile={selectedFile}
              changeView1={() => changeView("Voce")}
              changeView2={(e) => { e.preventDefault(); changeView("file") }}
              changeHandler={changeHandler}
              setSelectedFile={() => setSelectedFile(null)}
              function1={() => document.getElementById('fileInput').click()}
              updateGenerateThumbnail={updateGeneratedThumbnails}
              updateThumbnail={updateSelctedThumbnails}

              />

            : view == 3 ?

              <View3 
              
              selectedFile={selectedFile}
              changeView1={() => changeView("home")}
              changeView2={(e) => { e.preventDefault(); changeView("file") }}
              changeHandler={changeHandler}
              setSelectedFile={() => setSelectedFile(null)}
              function1={() => document.getElementById('fileInput').click()}
              updateGenerateThumbnail={updateGeneratedThumbnails}
              updateThumbnail={updateSelctedThumbnails}
              
              />

            : (view == 4 && menu==="Meet&Greet") ?

              <View4Meet 
              
                changeView1={() => changeView("home")}
                menu={menu}
                selectedFile={selectedFile}
                selectedThumbnail={selectedThumbnail}
                generatedThumbnails={generatedThumbnails}
                
              />

            : (view==4 && menu=="Voce") ?
              
              <View4Voce 
                
                changeView1={() => changeView("home")}
                menu={menu}
                selectedFile={selectedFile}
                selectedThumbnail={selectedThumbnail}
                generatedThumbnails={generatedThumbnails}


              />
              
            : ""

          }

        </div>

      </dialog>

    </>

  );
}

export default NewContentHome;