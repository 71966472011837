import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { IconButton } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PostModal from "../post/PostModal/PostModal";
import moment from "moment";
import "./Carousel.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import EventSlide from "../eventSlide/eventSlide";
// import { Pagination } from 'swiper/modules';
// import 'swiper/css/pagination';
import { Navigation } from 'swiper/modules';
import 'swiper/css/navigation';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

const Carousel = ({posts}) => {
  const sliderRef = useRef(null);
  const [swiperCentered, setSwiperCentered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState(false);

  //Modal stuff
  const openModal = (postID) => {
    console.log(postID)
    setIsModalOpen(true);
    setSelectedPost(posts.filter(post => post.id == postID)[0])
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedPost(null);
  };

  //Carousel stuff
  useLayoutEffect(() => {
    setTimeout(() => {
      if (posts.length > 3) //Fatto per evitare un problema che nasce quando le immagini sono meno larghe del contenitore --> Andrebbe bene anche con X>2 se i post fossero tutti grandi, ma con solo 3 post grandi il comportamento dello slider ha una differenza inutile, quindi è impostato almeno 4 post per iniziare a centrarli quando si scorrono in modo da essere (abbastanza) sicuri che la larghezza dei post superi quella del contenitore anche con la presenza di post piccoli --> Modificabile se sorgono altri problemi relativi alla questione
        setSwiperCentered(true);
    }, 0) //Fa ridere, ma serve
  });

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    document.getElementById("swiper-next-btn").style.opacity = "1"
    document.getElementById("swiper-next-btn").style.cursor = "pointer"
    document.getElementById("end-fade").style.opacity = "1"
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    document.getElementById("swiper-back-btn").style.opacity = "1"
    document.getElementById("swiper-back-btn").style.cursor = "pointer"
    document.getElementById("beginning-fade").style.opacity = "1"
    sliderRef.current.swiper.slideNext();
  }, []);

  const sliderBeginningReached = () => {
    document.getElementById("swiper-back-btn").style.opacity = "0"
    document.getElementById("swiper-back-btn").style.cursor = "default"
    document.getElementById("beginning-fade").style.opacity = "0"
  }

  const sliderEndReached = () => {
    document.getElementById("swiper-next-btn").style.opacity = "0"
    document.getElementById("swiper-next-btn").style.cursor = "default"
    document.getElementById("end-fade").style.opacity = "0"
  }

  return (
    <div className="carouselWrapper">

    <div id="swiper-back-btn" className="myNavButton backBtn" onClick={handlePrev}>
      <ArrowBackRoundedIcon className="icon" />
    </div>

    <div id="swiper-next-btn" className="myNavButton forwardBtn" onClick={handleNext}>
      <ArrowForwardRoundedIcon className="icon" />
    </div>

    <div id="beginning-fade" className="beginningFade" />
    <div id="end-fade" className="endFade" />

    <Swiper
      ref={sliderRef}
      slidesPerView={'auto'}
      centeredSlides={swiperCentered} //This had to be done due to a slight issue with the fact that images have different (non-predictable) widths
      centeredSlidesBounds={true}
      spaceBetween={20}
      simulateTouch={false}
      navigation={{
        prevEl: '.backBtn',
        nextEl: '.forwardBtn'
      }}
      onReachBeginning={sliderBeginningReached}
      onReachEnd={sliderEndReached}
      // modules={[Navigation]}
      // pagination={{
      //   clickable: true,
      // }}
      // modules={[Pagination]}
      className="mySwiper"
    >
    {posts != null && Array.isArray(posts) && posts.map((post) =>
      <SwiperSlide>
        <EventSlide {...post} onClick={() => openModal(post.id)}/>
      </SwiperSlide>
    )}
      {/* // FOR TESTING */}
      {/* <SwiperSlide>
        <EventSlide {...posts[0]} />
      </SwiperSlide>
      <SwiperSlide>
        <EventSlide {...posts[0]} />
      </SwiperSlide>
      <SwiperSlide>
        <EventSlide {...posts[0]} />
      </SwiperSlide> */}
    </Swiper>

    {isModalOpen && (selectedPost != null) && (
      <PostModal post={selectedPost} closeModal={closeModal} />
    )}
    </div>
  )
}

export default Carousel;