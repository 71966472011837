import { useState } from "react";
import { Form } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import UploadIconGray from "../../../assets/Icons/Media-Upload-Icon-Gray.png";
import UploadIconWhite from "../../../assets/Icons/Media-Upload-Icon-White.png";
import VideoThumbnail from "./Components/VideoThumbnail/VideoThumbnail";

const View3 = ( { selectedFile, changeView1, changeView2, changeHandler, setSelectedFile, function1, updateThumbnail, updateGenerateThumbnail } ) => {

  const [hovered2, setHovered2] = useState(false);
  const imageMimeType = /image\/(png|jpg|jpeg)/i;

  const handleThumbnailSelect = (thumbnail) => {
    updateThumbnail(thumbnail);
  };

  return (

    <div className='flex flex-col gap-4'>

      <button onClick={changeView1} className="flex flex-row w-fit justify-start items-center gap-4 hover:cursor-pointer">

        <ArrowBackIcon />
        
        <p className='font-bold text-[#1C1B1F]'> Crea Nuovo Meet&Greet </p>

      </button>

      <Form>

        <div className={`flex flex-col w-[50rem] justify-center items-center border-2 border-dashed border-[#AEAAAE] rounded-md ${hovered2 ? 'bg-[#1C1B1F]' : 'bg-white'}`} >

          {selectedFile ? (

            // If a file is selected, show details

            <div className="flex flex-col w-full gap-6 pb-8 justify-center items-center">

              {selectedFile.type.match(imageMimeType) ? (

                  <img alt="" src={URL.createObjectURL(selectedFile)} className="rounded-t-md"/>

                ) : (

                  <>
                  
                    <video controls alt="" src={URL.createObjectURL(selectedFile)} className="rounded-xl mt-8"/>
                    
                    <VideoThumbnail videoFile={selectedFile} onThumbnailSelect={handleThumbnailSelect}/>

                  </>

              )}

              <p> {selectedFile.name} </p>

              <div className="flex flex-row justify-center items-center gap-4">

                <button onClick={changeView2} className='w-60 h-12 rounded-[50px] font-bold uppercase text-[18px] text-white bg-[#1C1B1F] border-2 border-[#1C1B1F] hover:text-[#1C1B1F] hover:bg-white hover:border-[#1C1B1F]'>
                  
                  Conferma
                  
                </button>

                <button onClick={setSelectedFile} className='w-60 h-12 rounded-[50px] font-bold uppercase text-[18px] text-white bg-deletebtn border-2 border-deletebtn hover:bg-deletebtnhover hover:border-deletebtnhover'>
                  
                  Annulla
                  
                </button>

              </div>

            </div>

          ) : (

            // If no file is selected, show the initial block

            <div 
            
            onClick={ () => { function1(); setHovered2(false) }}
            onMouseEnter={() => setHovered2(true)}
            onMouseLeave={() => setHovered2(false)}
            className="flex flex-col w-[50rem] gap-6 p-8 justify-center items-center hover:cursor-pointer"
          
          >

            <div className="flex flex-col justify-center items-center gap-4">

              <img src={hovered2 ? UploadIconWhite : UploadIconGray} alt="uploadicon" className="w-fit h-20"/>

              <div className="flex flex-col justify-center items-center">

                <p className={`font-bold ${hovered2 ? 'text-white' : 'text-[#1C1B1F]'}`}> Seleziona un file multimediale </p>

                <p className={`text-[16px] font-medium text-center w-[22rem] ${hovered2 ? 'text-white' : 'text-[#1C1B1F]'}`}> Il contenuto caricato verrà trasmesso sul totem scelto all'orario del Meet&Greet. </p>

                <p className={`text-[16px] font-medium text-center ${hovered2 ? 'text-white' : 'text-[#1C1B1F]'}`}> I video devono durare massimo 60 secondi. </p>

              </div>

            </div>

            <div className="flex flex-col justify-center items-center">

              <p className={`text-[16px] font-medium text-center ${hovered2 ? 'text-white' : 'text-[#1C1B1F]'}`}> Formati supportati: MP4, JPG, PNG </p>

              <p className={`text-[16px] font-medium text-center ${hovered2 ? 'text-white' : 'text-[#1C1B1F]'}`}> Risoluzione 1920x1080 (verticale o orizzontale) </p>

            </div>

            <div className="flex justify-center items-center">

              <button className={`w-60 h-12 rounded-[50px] font-bold uppercase text-[18px] ${hovered2 ? 'text-[#1C1B1F] bg-white border-[#1C1B1F]' : 'text-white bg-[#1C1B1F] border-2 border-[#1C1B1F]'}`}>
                
                Seleziona un file
                
              </button>

            </div>

          </div>

          )}                    

          <input

            id="fileInput"
            onChange={changeHandler}
            type="file"
            name="file"
            accept=".png, .jpg, .jpeg, .mp4"
            className="hidden"

          />

        </div>

      </Form>

    </div>

  );
}

export default View3;