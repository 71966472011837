// CensoredWords.js
const censoredWords = {
  english: [
    "swear",
    "curse",
    "inappropriate",
    "fuck",
    "bitch",
    "kill you",
    // Add more English words as needed
  ],
  italian: [
    "parolaccia",
    "volgarità",
    "offensivo",
    "vaffanculo",
    "fanculo",
    "vai a fare in culo",
    // Add more Italian words as needed
  ],
};

export default censoredWords;
