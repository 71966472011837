import { useNavigate,Form } from 'react-router-dom';
import { useContext, useState } from "react";
import { AuthContext } from "../../context/authContext";
import classes from './NewContent.module.css';
import Modal from '../modal/Modal';


 const NewContent = ({children}) => {
  debugger;
  const [file, setFile] = useState(children);

  return (
    <Modal>
      
      <div className={classes.wrapper}>
        <span>Crea Nuovo</span>
        <Form  className={classes.form} >
        {file && (
              <img className="file" alt="" src={URL.createObjectURL(file)} />
            )}

      </Form>
     
    
    </div>
    </Modal>
  );
}

export default NewContent;