import "./floatingNavIcon.scss";
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

const FloatingNavIcon = ({ToggleMobileNav}) => {

    return ( 
        <div id="floating-nav-icon" className="floatingNavIconDiv" onClick={ToggleMobileNav}>
            <ChevronRightRoundedIcon className="icon"/>
        </div>
    );
}

export default FloatingNavIcon;