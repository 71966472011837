import React from 'react';
import './eventSlide.scss';

const borderColorsFromType = { //TODO: Da completare e magari mettere in un file separato
    "Talenti": "#D41882",
    "Ambiente": "#1BC07A",
    "Mobilità": "#2848CD",
    "Servizi per la comunità": "#F1A449",
    "Centri Culturali": "#E7D849",
    null: "white"
}

const defaultBorderColor = 'white';

const imageWidthsFromType = { //TODO: Cercare di rimuovere questa dipendenza --> Sarebbe stato bello non dover ricavare una larghezza e lasciare che la dettasse l'immagine, con il suo aspect-ratio, ma flexbox sembra avere un bug per cui il genitore di un img rimane della larghezza INTRINSECA e non quella effettiva se è un container flex (o grid)
    0: "min(25rem, 100vw - 40px - 2rem - 2px)" //Accounts for (in order) home padding, slide padding, border width
}

const EventSlide = ({ title, thumbnail, type, profilePic, name, onClick, category }) => {
    return (
        <div className="eventSlideContainer" style={{borderColor: borderColorsFromType[category]||defaultBorderColor}} onClick={onClick}>
            <picture className='eventPicture' style={{ width: imageWidthsFromType[type] }}>
                {type === 'video/mp4' ? (
                    <video controls className="w-full h-full object-cover">
                    <source src={`data:${thumbnail.type};base64,${thumbnail.content}`} type="video/mp4" />
                    Your browser does not support the video tag.
                    </video>
                ) : (
                    <React.Fragment>
                    <source media="(min-width:0px)" srcSet={`data:${thumbnail.type};base64,${thumbnail.content}`} />
                    <img src={`data:${thumbnail.type};base64,${thumbnail.content}`} alt={title} />
                    </React.Fragment>
                )}
            </picture>

            <div className='eventInfo'>
                <picture title={name}>
                    <source media="(min-width:0px)" srcset={`data:${profilePic.type};base64,${profilePic.content}`} />
                    <img src={`data:${profilePic.type};base64,${profilePic.content}`} alt={name} />
                </picture>

                <h2>{title}</h2>
            </div>
        </div>
    );
}

export default EventSlide;


//Post structure
// {
//     city
//     createdAt
//     id
//     media
//     thumbnail
//     name
//     profilePic
//     title
//     type
//     userId
// }