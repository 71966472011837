import React, { useState, useEffect, useRef } from "react";
import { makeRequest } from "../../axios";
import CitySelect from "./Components/CitySelect/CitySelect";
import DatePicker from "./Components/DatePicker/DatePicker";
import TotemSelect from "./Components/TotemSelect/TotemSelect";
import "./Totem.css";
import { getAllVideos } from "../../FetchCalls";
import ReactPlayer from 'react-player';

const playlist = [
  {
    title: "Video 1",
    source: "upload/sample-5s.mp4",
  },
  {
    title: "Video 2",
    source: "upload/file_example_MP4_480_1_5MG.mp4",
  },
];

function Totem() {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [selectedCity, setSelectedCity] = useState(""); // Change the state name to setSelectedCity
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [totems, setTotems] = useState([]); // Add state for totems
  const [filteredTotems, setFilteredTotems] = useState([]);
  const [showTotemsSection, setShowTotemsSection] = useState(false);
  const [checkList, setCheckList] = useState([]);
  const [videos, setVideos] = useState([]); // Add state for videos

  const playVideo = (index) => {
    setCurrentVideoIndex(index);
    const videoPlayer = document.getElementById("video-player");
    const videoSource = process.env.PUBLIC_URL + playlist[index].source;
    videoPlayer.src = videoSource;
    videoPlayer.load(); // Ensure the video is reloaded with the new source
    videoPlayer.play();
  };



  const citiesArray = [
    { label: "Salerno", value: "Salerno", key: "1" },
    { label: "Benevento", value: "Benevento", key: "2" },
    { label: "Caserta", value: "Caserta", key: "3" },
    { label: "Napoli", value: "Napoli", key: "4" },
    { label: "Avellino", value: "Avellino", key: "5" },
  ]
  // useEffect to call the API and fetch totems
  useEffect(() => {
    (async () => {
      try {
        const city = citiesArray.find((city) => city.label === selectedCity);
        const cleardate = selectedDate.toISOString().split("T")[0].replace(/-/g, "/");
        // but should be day/month/year
        const checked = filteredTotems.filter((item) => item.checked);
        const ids = checked.map((item) => item.id);
        const dateee = cleardate.split("/")[2] + "/" + cleardate.split("/")[1] + "/" + cleardate.split("/")[0];
        const data = {
          // find the id of the city
          checked: ids,
          //2023-09-16T17:17:11.000Z format to day/month/year
          date: dateee,
        };
        const res = await getAllVideos(data);
        console.log(res);
        setVideos(res);
      } catch (error) {
        console.error("Failed to fetch totems:", error);
      }
    })();
  }, [filteredTotems])


  useEffect(() => {
    if (selectedCity) {
      makeRequest
        .get("/totem")
        .then((res) => {
          var toReturn = [];
          res.data.forEach((element) => {
            if (element.City === selectedCity) {
              toReturn.push({
                id: element.id,
                city: element.City,
                lat: Number(element.lat),
                lng: Number(element.lng),
                address: element.indirizzo,
                checked: false,
              });
            }
          });
          setTotems(toReturn);
          setFilteredTotems(toReturn);
        })
        .catch((error) => {
          console.error("Error fetching totems:", error);
        });
    }
  }, [selectedCity]);

  const cityChange = (event) => {
    setSelectedCity(event);
    const totemsInCity = totems.filter((totem) => totem.city === event);
    setCheckList(totemsInCity);
    setFilteredTotems(totemsInCity);
    setShowTotemsSection(true);
  };

  const isChecked = (item) =>
    item.checked ? "checked-item" : "not-checked-item";

  const toggleCheck = (itemId) => {
    setFilteredTotems((prevTotems) =>
      prevTotems.map((item) =>
        item.id === itemId ? { ...item, checked: !item.checked } : item
      )
    );
  };

  const handleSearch = (searchInput) => {
    const lowercaseSearchInput = searchInput.toLowerCase();
    if (lowercaseSearchInput.trim() === "") {
      // If the search input is empty, show all totems
      setFilteredTotems(totems);
    } else {
      // Otherwise, filter totems based on the search input
      const filtered = totems.filter((totem) =>
        totem.address.toLowerCase().includes(lowercaseSearchInput)
      );
      setFilteredTotems(filtered);
    }
  };

  const dateChange = (date) => {
    setSelectedDate(date); // Update selectedDate directly with the date object
  };

  const playerRef = useRef(null);

  const playNextItem = () => {
    if (currentItemIndex < videos.length - 1) {
      setCurrentVideoIndex(currentItemIndex + 1);
    }
  };

  const playNextVideo = () => {

    if (currentVideoIndex < videos.length - 1) {
      //playVideo(currentVideoIndex + 1);
      setCurrentVideoIndex(currentVideoIndex + 1);
    }
  };

  const playPrevVideo = () => {

    if (currentVideoIndex > 0) {
      // playVideo(currentVideoIndex - 1);
      setCurrentVideoIndex(currentVideoIndex - 1);
    }
  };


  return (

    <div className="flex flex-col w-full justify-center items-center gap-6">

      <div className="flex flex-col gap-12">

        <div className="flex flex-row mt-8 gap-8">

          <CitySelect City={selectedCity} onCitySelect={cityChange} />
          <DatePicker selectedDate={selectedDate} onDateChange={dateChange} />

        </div>

        {selectedCity && selectedDate && (

          <div className="flex">

            <TotemSelect

              selectedCity={selectedCity}
              totems={filteredTotems}
              handleSearch={handleSearch}
              checkList={checkList}
              setCheckList={setCheckList}
              setFilteredTotems={setFilteredTotems}
              isChecked={isChecked}
              toggleCheck={toggleCheck}

            />

          </div>

        )}

      </div>
      <div className="totem-container">
        {showTotemsSection && (


          <div>
            <h1 className="text-3xl text-white font-bold mb-4">

              Totem Video Player

            </h1>

  

          </div>
        )}

        {videos.length > 0 ? (
          <div>

            {videos[currentVideoIndex].media.type === 'video/mp4' ? (
              <ReactPlayer
                ref={playerRef}
                url={"data:" + videos[currentVideoIndex].media.type + ";base64," + videos[currentVideoIndex].media.content}
                playing={true}
                controls={true}
                
              />
            ) : (
              <img
                src={"data:" + videos[currentVideoIndex].media.type + ";base64," + videos[currentVideoIndex].media.content}
                alt="Current item"
                style={{ maxWidth: '100%', maxHeight: '80vh' }}
              />
            )}
            <div className="controls mt-4 gap-4">

              <button onClick={playPrevVideo} disabled={currentVideoIndex === 0} className="w-40 h-12" >

                Previous

              </button>

              <button onClick={playNextVideo}  disabled={currentVideoIndex === videos.length - 1} className="w-40 h-12" >

                Next

              </button>

            </div>
          </div>
        ) : ""}
      </div>
    </div>





  );
}

export default Totem;
