import { useContext, useEffect, useState, useRef } from "react";
import "./post.scss";
import './post.css';
import { Link } from "react-router-dom";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import { AuthContext } from "../../context/authContext";
import PlaceIcon from '@mui/icons-material/Place';
import { toast } from 'react-toastify';
import censoredWords from './Data/CensoredWords';
import PostModal from "./PostModal/PostModal";
import SendRoundedIcon from '@mui/icons-material/SendRounded';

const VerticalPost = ({ post, postId }) => {
  const { currentUser } = useContext(AuthContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const [commentOpen, setCommentOpen] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [desc, setDesc] = useState("");
  const textareaRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const queryClient = useQueryClient();

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleClick(e);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  // Helper function to censor inappropriate words
  const censorInappropriateWords = (comment) => {

    const allInappropriateWords = [
      ...censoredWords.english,
      ...censoredWords.italian,
    ];

    const censoredComment = comment.replace(
      new RegExp(`\\b(${allInappropriateWords.join('|')})\\b`, 'gi'),
      (match) => match[0] + '*'.repeat(match.length - 2) + match[match.length - 1]
    );

    if (censoredComment !== comment) {
      toast.error(
        'Parole e termini oltraggiosi non sono consentiti.',
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return null; // Return null if inappropriate words are found
    }
    return censoredComment;
  };
  
  const handleClick = async (e) => {
    e.preventDefault();
  
    // Check if the comment is empty or contains only spaces
    if (desc.trim() === '') {
      toast.error("Mi dispiace, non puoi lasciare un commento senza testo. Riempi il campo prima di procedere", {
        position: 'top-right',
        autoClose: 5000, // Adjust as needed
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
  
    // Check if the comment contains inappropriate words
    const censoredDesc = censorInappropriateWords(desc);
  
    if (censoredDesc === null) {
      return; // Prevent submitting if inappropriate words are found
    }
  
    // Submit the comment and show a success toast

    mutation2.mutate({ desc: censoredDesc, postId });
    setDesc("");
    toggleCommentInput();

    toast.success("Il tuo commento è stato inoltrato con successo!", {
      position: 'top-right',
      autoClose: 5000, // Adjust as needed
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  };


  const toggleCommentInput = () => {
    setShowInput(!showInput);
    if (!showInput) {
      // When expanding the input, focus the textarea if available
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
    }
  };

  const hideCommentOnBlur = (e) => {
    // if the blur was because of outside focus
    // currentTarget is the parent element, relatedTarget is the clicked element
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShowInput(false);
    }
  };

  // Determine the media content type (image or video)
  const isVideo = post.media.type === 'video/mp4';

  return (
      
    <div className="verticalPost flex flex-col gap-4">

      <div className="flex rounded-md hover:ring-2 hover:ring-white">

          <img
            onClick={openModal}
            src={`data:${post.thumbnail.type};base64,${post.thumbnail.content}`}
            alt=""
            className="w-full h-full hover:cursor-pointer rounded-md"
          />

      </div>

      <div className="postInfoContainer">

        <div className="opInfo">

          <img

            src={`data:${post.profilePic.type};base64,${post.profilePic.content}`}
            alt=""
            className="profilePic flex mt-1 mr-2 justify-center items-center border-2 rounded-full object-cover"

          />

          <h6 className="opUsername">{post.name}</h6>
        </div>
        
        <div className="postInfoDiv">
          <div className="postInfo">
            <h2>{post.title}</h2>
            <div className="divider" />
            <div className="location">
              <PlaceIcon className="icon" />
              <h6 className="text-2xl">{post.city}</h6>
            </div>
          </div>

          <Link to={`/profile/${post.userId}`}>
            <h6 className="opUsername">{post.name}</h6>
          </Link>
        </div>

        <div className="interactionsDiv">

          <div className="w-fit pl-2 mt-8 hover:cursor-pointer" onClick={openModal}>

            <button onClick={() => openModal()} className="text-[#787579] font-semibold hover:text-white">

            {post.comments_counter === null ? (
              <p>Caricamento...</p>
            ) : post.comments_counter === 0 ? (
              <p>Ancora nessun commento</p>
            ) : post.comments_counter === 1 ? (
              <p>Mostra il commento a questo post</p>
            ) : (
              <p>Mostra i {post.comments_counter} commenti a questo post</p>
            )}

            </button>

          </div>
          
          {showInput ? (

            <div id="comment" className="flex flex-row w-full h-40 hover:cursor-pointer gap-4" onBlur={hideCommentOnBlur}>

              <div className="inputfield flex w-full h-full">

                <textarea
                  ref={textareaRef}
                  type="text"
                  placeholder="Scrivi un commento..."
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                  onKeyDown={handleKeyPress}
                  rows="1"
                  autoFocus
                />

              </div>

              <div className="flex flex-col h-full justify-center items-center">

                <button onClick={handleClick} className="flex justify-center items-center w-[6.5rem] h-full rounded bg-[#484649] hover:bg-[#666] text-[#FFFBFE]">

                  <SendRoundedIcon className="!w-8 !h-8"/>
                  
                </button>

              </div>

            </div>

          ) : (

            <div onClick={toggleCommentInput} className="w-full h-20 py-2 pl-2 hover:cursor-pointer border-2 border-[#1C1B1F] hover:border-white rounded-md text-[#787579] font-medium hover:text-white" >

              <p className=""> Scrivi un commento... </p>
            
            </div>

          )}
        </div>

      </div>

      {isModalOpen && (

        <PostModal post={post} postId={postId} closeModal={closeModal} />

      )}

    </div>
      
  );
};

export default VerticalPost;