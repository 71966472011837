import "./mobileNav.scss";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../context/authContext";
import HomeIcon from '@mui/icons-material/Home';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

const MobileNav = ({ToggleMobileNav}) => {

    const { currentUser } = useContext(AuthContext);
    const navigate = useNavigate();
  
    const goToHome = () => {
        navigate("/home");
        ToggleMobileNav();
    };
  
    const goToExplore = () => {
        navigate("/home/explore");
        ToggleMobileNav();
    };
  
    const goToCreate = () => {
        navigate("/home/create-content-home");
        ToggleMobileNav();
    };
  
    const goToProfile = () => {
        navigate(`/home/profile/${currentUser.id}`);
        ToggleMobileNav();
    };

    return (
        <div id="mobile-nav" className="mobileNavOuterDiv hidden">
            <div className="backgroundDiv" />

            <div className="mainDiv">
                <button onClick={goToHome}>
                    <HomeIcon className="navIcon"/>
                    <h6 className="navText">Home</h6>
                </button>

                <button onClick={goToExplore}>
                    <ExploreOutlinedIcon className="navIcon"/>
                    <h6 className="navText">Esplora</h6>
                </button>

                <button onClick={goToCreate}>
                    <AddBoxOutlinedIcon className="navIcon"/>
                    <h6 className="navText">Nuovo</h6>
                </button>

                <button onClick={goToProfile}>
                    <img className="navImg" src={"/upload/" + currentUser.profilePic} alt=""/>
                    <h6 className="navText">Profilo</h6>
                </button>
            </div>
        </div>
    );
}

export default MobileNav;