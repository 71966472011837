import { useState } from "react";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../../axios";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DatePicker from "./Components/DatePicker/DatePicker";
import HoursSelect, { hourRanges } from "./Components/HoursSelect/HoursSelect";
import SearchIcon from '@mui/icons-material/Search';
import "./View.css";
import "../map.css";
import CitySelect from "./Components/CitySelect/CitySelect";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import VideoThumbnail from "./Components/VideoThumbnail/VideoThumbnail";

const View4Meet = ({ changeView1, selectedFile, userId, selectedThumbnail, generatedThumbnails }) => {
  const [city, setCity] = useState("");
  const [selectedCity, setselectedCity] = useState(false);
  const [center, setCenter] = useState({});
  const [Hour, setHour] = useState("");
  const [totems, setTotems] = useState(null);
  const [checkList, setCheckList] = useState([""]);
  const [description, setDescription] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { isLoaded } = useLoadScript({googleMapsApiKey: "AIzaSyAD7c1E326n2Fr-6U0gXIJ0wr3J6q__WCU"});
  const [checked, setChecked] = useState([]);
  const [filteredTotems, setFilteredTotems] = useState([]);
  const [selectedFile2, setSelectedFile2] = useState(selectedThumbnail);

  // State variables for managing visibility
  const [nameFieldValue, setNameFieldValue] = useState("");
  const [showCitySection, setShowCitySection] = useState(false);
  const [showTotemsSection, setShowTotemsSection] = useState(false);
  const [showDateTimeBlock, setShowDateTimeBlock] = useState(false);
  const [isDateTimeSelected, setIsDateTimeSelected] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleThumbnailSelect = (thumbnail) => {
    setSelectedFile2(thumbnail);
  };

  const handleNameFieldChange = (event) => {
    setNameFieldValue(event.target.value);
    setShowCitySection(!!event.target.value);
  };

  const handleTotemSelection = () => {
    setShowDateTimeBlock(true);
  };

  const handleDateTimeSelection = () => {
    const isDateSelected = selectedDate !== null;
    const isTimeSelected = Hour !== "";
    const isValidSelection = isDateSelected && isTimeSelected;
    setIsDateTimeSelected(isValidSelection);
  };


  const checkedItems = checked.length
    ? checked.reduce((total, item) => {
        return total + ", " + item;
      })
    : "";

  const date = new Date();

  let day = date.getDate();
  let month = date.getMonth();
  let year = date.getFullYear();

  const markers = [];

  const { isLoading, error, data } = useQuery(["totems"], () =>
    makeRequest.get("/totem").then((res) => {
      var toReturn = [];
      res.data.forEach((element) => {
        toReturn.push({
          id: element.id,
          city: element.City,
          lat: Number(element.lat),
          lng: Number(element.lng),
          address: element.indirizzo,
          checked: false,
        });
      });
      setTotems(toReturn);
      return toReturn;
    })
  );

  const isChecked = (item) =>
    item.checked ? "checked-item" : "not-checked-item";

  const toggleCheck = (itemId) => {
    setFilteredTotems((prevTotems) =>
      prevTotems.map((item) =>
        item.id === itemId ? { ...item, checked: !item.checked } : item
      )
    );
  };


  const imageMimeType = /image\/(png|jpg|jpeg)/i;

  const citiesArray = [
    { label: "Salerno", value: "Salerno", key: "1" },
    { label: "Benevento", value: "Benevento", key: "2" },
    { label: "Caserta", value: "Caserta", key: "3" },
    { label: "Napoli", value: "Napoli", key: "4" },
    { label: "Avellino", value: "Avellino", key: "5" },
  ].map((cityName, index) => ({
    id: index,
    name: cityName,
  }));

  // Dynamically create select list
  let cities = [];
  citiesArray.map((item) =>
    cities.push({
      label: item.name.label,
      value: item.name.value,
      id: item.name.key,
    })
  );

  const cityChange = (event) => {
    setselectedCity(true);
    setCity(event)
    const cityCoordinates = {
      "Salerno": { lat: 40.68245, lng: 14.7682 },
      "Napoli": { lat: 40.85884, lng: 14.24896 },
      "Caserta": { lat: 41.07303, lng: 14.33282 },
      "Avellino": { lat: 40.91489, lng: 14.79359 },
      "Benevento": { lat: 41.12954, lng: 14.78092 }
    };
  
    if (cityCoordinates[event]) {
      setCenter(cityCoordinates[event]);
    }
    const totemsInCity = totems.filter(totem => totem.city === event);
    setCheckList(totemsInCity);
    setFilteredTotems(totemsInCity);
    setShowTotemsSection(true);
  };

  // from base64 to blob
  const dataURLtoFile = (dataurl, filename) => {

    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });

  };
  

  const hourChange = (hours) => {
    setHour(hours);
  };

  const dateChange = (date) => {
    setSelectedDate(date); // Update selectedDate directly with the date object
  };

  const handleSearch = (searchInput) => {
    const lowercaseSearchInput = searchInput.toLowerCase();
    if (lowercaseSearchInput.trim() === "") {
      // If the search input is empty, show all totems
      setFilteredTotems(totems);
    } else {
      // Otherwise, filter totems based on the search input
      const filtered = totems.filter((totem) =>
        totem.address.toLowerCase().includes(lowercaseSearchInput)
      );
      setFilteredTotems(filtered);
    }
  };

  const handleSubmission = async (event) => {
    event.preventDefault();
    try {
      // Create a FormData object
      let thumbnailFile;
      if (selectedFile2) {
        thumbnailFile = dataURLtoFile(selectedFile2, "thumbnail.jpg");
      } else {
        thumbnailFile = selectedFile;
      }
      const formData = new FormData();
      const checked = filteredTotems.filter((item) => item.checked);
      const ids = checked.map((item) => item.id);
      const idofthehours = hourRanges.find((item) => item.value === Hour).key;
      // Append necessary data to the formData object
      formData.append("desc", nameFieldValue);
      formData.append("city", (citiesArray.find((item) => item.name.value === city) || {}).name?.key);
      formData.append("files", selectedFile);
      formData.append("files", thumbnailFile);
      /// convert selectedDate to x/x/x format
      const cleardate = selectedDate.toISOString().split("T")[0].replace(/-/g, "/");
      const dateee = cleardate.split("/")[2] + "/" + cleardate.split("/")[1] + "/" + cleardate.split("/")[0];
      formData.append("date", dateee);
      formData.append("hour", idofthehours);
      formData.append("checked", ids); // Remove the square brackets
  
      // Send the request
      const postResult = await makeRequest.post("/upload/meetgreet", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      if (postResult.status === 200) {

        // Manually trigger a refetch of the posts
        queryClient.invalidateQueries(["allposts"]);

        console.log("File uploaded successfully.");
        // Display a success toast
        toast.success("Evento creato con successo!", {
          autoClose: 3000, // Toast will auto-close after 3 seconds
        });
        // Redirect to "/" when the response is 200
        navigate("/home");
        
      } else {
        console.log("File upload failed.");
        // Display an error toast
        toast.error("Non è stato possibile creare l'evento. Riprova");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Display an error toast for any other errors
      toast.error("Si è verificato un errore. Riprova più tardi.");
    }
  };

  return (

    <div className="flex flex-col w-[60rem] gap-8">

      <div className="flex flex-col">

        <button onClick={changeView1} className="flex flex-row w-fit justify-start items-center gap-4 hover:cursor-pointer">

          <ArrowBackIcon />

          <p className="font-bold text-[#1C1B1F]"> Crea un Nuovo Meet&Greet </p>

        </button>

        <div className="flex ml-10">

          <p className="font-medium text-base text-[#1C1B1F]">
            
            I contenuti caricati verranno presi in esame dalla nostra redazione e resi pubblici solo previa autorizzazione.

          </p>

        </div>

      </div>

      <div className="flex flex-row gap-4">

        <div className="flex min-w-max">

          {selectedFile.type.match(imageMimeType) ? (
            
            <img

              alt=""
              src={URL.createObjectURL(selectedFile)}
              className="rounded-sm w-60 h-60 object-cover"

            />

          ) : (

            <img alt="" src={selectedFile2} className="rounded-sm w-60 h-60 object-cover" />

          )}

        </div>

        <div className="flex flex-col w-full justify-between">

          <div className="flex flex-col">

            <div className="flex self-start">

              <p className="font-bold text-base text-[#1C1B1F]"> Titolo </p>

            </div>

            <textarea

              id="Name"
              name="Name"
              onChange={handleNameFieldChange}
              className="w-full h-12 p-2 rounded-md bg-[#E6E1E5] resize-none overflow-y-auto"

            />

          </div>

          {selectedFile.type.match(/video\/*/) && (

            <VideoThumbnail videoFile={selectedFile} onThumbnailSelect={handleThumbnailSelect} />
            
          )}

        </div>

      </div>

      <div className="flex flex-col gap-8">

        {showCitySection && (

          <>

          <hr/>

          <div className="flex flex-col w-full gap-2">

            <div className="flex flex-col self-start gap-6">

              <h1 className="font-bold text-xl text-[#1C1B1F]">
                
                Scegli dove organizzare il meet&greet

              </h1>

              <p className="font-bold text-base text-[#1C1B1F]">
                
                Scegli una Città

              </p>

            </div>
            
            <CitySelect City={selectedCity} onCitySelect={cityChange} />

          </div>

          <hr/>

          </>

        )}

        {showTotemsSection && (

          <>

          <div className="flex flex-row w-full gap-4">

            <div className="flex flex-col justify-center items-center w-1/2 h-full gap-4">

              <div className="flex w-full justify-start">

                <p className="font-bold text-[#1C1B1F]"> {city} </p>

              </div>

              <div className="flex flex-col w-full">

                <div className="flex w-fit self-end mb-4 border-b-2 border-[#E6E1E5]">

                  <input

                    type="text"
                    placeholder="Cerca una via specifica"
                    onChange={(e) => handleSearch(e.target.value)}
                    className="text-sm px-2 py-1 rounded-md outline-none"

                  />

                  <SearchIcon className="text-[#1C1B1F]"/>

                </div>

                {filteredTotems && totems.length > 0 ? (

                  filteredTotems.map((item, index) => (

                    <button 
                    
                      key={item.id}
                      onClick={() => {
                        toggleCheck(item.id);
                        handleTotemSelection(); // Call handleTotemSelection when a totem is clicked.
                      }}
                      className="flex flex-row w-full h-10 pr-4 justify-between items-center rounded-md gap-4 text-[#1C1B1F] hover:bg-[#1C1B1F] hover:text-white"
                    
                    >

                      <div className={`${isChecked(item)} flex flex-row justify-center items-center gap-2`} >

                        <LocationOnIcon />

                        <p className="text-lg font-medium"> {item.address} </p>

                      </div>

                      <input

                        value={item.id}
                        id={"checkbox" + item.id}
                        type="checkbox"
                        checked={item.checked || false}
                        className="checkbox-input"
                        onChange={() => {}}

                      />

                    </button>

                  ))

                  ) : (

                  <span> Nessun Totem Disponibile </span>

                )}

              </div>

            </div>

            <GoogleMap mapContainerClassName="map-container" center={center} zoom={10} >

              {totems.map(({ id, city, lat, lng, address }) => (

                <MarkerF position={{ lat, lng }} icon={"http://maps.google.com/mapfiles/ms/icons/green-dot.png"} />

              ))}

            </GoogleMap>

          </div>

        <hr />

        </>

        )}

      </div>

      {showDateTimeBlock && (

        <div className="flex flex-col gap-12">

          <h1 className="font-bold text-xl text-[#1C1B1F]">
            
            Scegli ora e giorno per il Meet&Greet

          </h1>

          <div className="flex flex-row w-fit gap-8">

            <div className="flex flex-col justify-start items-start gap-4">

              <p className="font-bold text-base text-[#1C1B1F]">
                
                Scegli Data

              </p>

              <DatePicker selectedDate={selectedDate} onDateChange={dateChange} />

            </div>

            <div className="flex flex-col justify-start items-start gap-4">

              <p className="font-bold text-base text-[#1C1B1F]"> Orario </p>

              <HoursSelect selectedHour={Hour} onHourChange={hourChange} />

            </div>

          </div>

        </div>

      )}

        <div className="flex flex-col justify-center items-center">

          <button onClick={handleSubmission} className="flex w-fit h-12 my-10 px-8 justify-center items-center rounded-3xl text-[#1C1B1F] bg-white border-2 border-[#1C1B1F] hover:text-white hover:bg-[#1C1B1F]">

            <p className="text-xl font-bold">

              Invia richiesta di pubblicazione

            </p>

          </button>

        </div>

  

    </div>

  );
};

export default View4Meet;
