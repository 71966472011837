import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import DefaultImg from ".././../../assets/PlaceholderImage.png";
import Comments from "../../comments/Comments";
import PlaceIcon from '@mui/icons-material/Place';
import { Link } from "react-router-dom";
import censoredWords from '../Data/CensoredWords';
import { toast } from 'react-toastify';
import { makeRequest } from "../../../axios";
// import './PostModal.css';
import './PostModal.scss';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import EmojiEmotionsRoundedIcon from '@mui/icons-material/EmojiEmotionsRounded';

const PostModal = ({ post, postId, closeModal }) => {

  const textareaRef = useRef(null);
  const [desc, setDesc] = useState("");
  const modalRef = useRef(null);  
  const queryClient = useQueryClient();
  const [sendCommentDisabled, setSendCommentDisabled] = useState(true);
  const videoRef = useRef(null);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  // Helper function to censor inappropriate words
  const censorInappropriateWords = (comment) => {

    const allInappropriateWords = [
      ...censoredWords.english,
      ...censoredWords.italian,
    ];

    const censoredComment = comment.replace(
      new RegExp(`\\b(${allInappropriateWords.join('|')})\\b`, 'gi'),
      (match) => match[0] + '*'.repeat(match.length - 2) + match[match.length - 1]
    );

    if (censoredComment !== comment) {
      toast.error(
        'Parole e termini oltraggiosi non sono consentiti.',
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return null; // Return null if inappropriate words are found
    }
    return censoredComment;
  };

  const mutation = useMutation(
    (newComment) => {
      return makeRequest.post("/comments", newComment);
    },
    {
      onMutate: async (newComment) => {
        await queryClient.cancelQueries(["comments", postId]);
  
        const newCommentData = {
          id: newComment.id,
          profilePic: newComment.profilePic,
          name: newComment.name,
          desc: newComment.desc,
          createdAt: new Date().toISOString(),
        };
  
        queryClient.setQueryData(["comments", postId], (old) => ({
          ...old,
          data: Array.isArray(old?.data) ? [...old.data, newCommentData] : [newCommentData],
        }));
  
        return { newCommentData };
      },
      onSuccess: (data, variables, context) => {
        const newCommentData = context.newCommentData;
      
        queryClient.setQueryData(["comments", postId], (old) => ({
          ...old,
          data: [...old.data, newCommentData],
        }));
      
        queryClient.invalidateQueries(["comments", postId]);
      },
    }
  );

  const handleChange = (e) => {
    if (e.target.value.trim() !== "") {
      setSendCommentDisabled(false);
    } else {
      setSendCommentDisabled(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  

  
    // Check if the comment contains inappropriate words
    const censoredDesc = censorInappropriateWords(e.target.comment.value);
  
    if (censoredDesc === null) {
      return; // Prevent submitting if inappropriate words are found
    }
  
    // Prepare the comment data
    const newComment = {
      desc: censoredDesc,
      postId: postId,
    };
  
    // Call the mutation to submit the comment
    await mutation.mutateAsync(newComment);
  
    // Clear the comment input
    setDesc("");
    e.target.reset();
  
    // Show success toast (optional)
    toast.success("Il tuo commento è stato inoltrato con successo!", {
      position: 'top-right',
      autoClose: 5000, // Adjust as needed
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    // Function to close the modal on click outside
    const closeOnOutsideClick = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        closeModal();
      }
    };

    // Attach the event listener to the document
    document.addEventListener("mousedown", closeOnOutsideClick);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", closeOnOutsideClick);
    };
  }, [closeModal]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('loadedmetadata', () => {
        videoRef.current.play().catch(error => {
          // Handle any errors here
          console.error('Video playback error:', error);
        });
      });
    }
  }, []);
    
  return (

    <div className="modalOuterDiv">
      <button className="closeBtn" onClick={() => closeModal()}><CloseRoundedIcon /></button>

      {post.media.type === 'video/mp4' ? (
        
        <video
          ref={videoRef}
          className="eventImg"
          controls
          autoplay  // Add the autoplay attribute
        >

          <source
            src={`data:${post.media.type};base64,${post.media.content}`}
            type="video/mp4"
          />

          Your browser does not support the video tag.
          
        </video>

      ) : (
        
        <img
          className="eventImg"
          src={`data:${post.media.type};base64,${post.media.content}`}
          alt={post.title}
        />

      )}

      <div className="interactionsDiv">
        <div className="postInfoDiv_Modal">
          <div className="opInfo_Modal">
            <img className="opProfilePic_Modal" src={`data:${post.profilePic.type};base64,${post.profilePic.content}`} alt={post.name}/>
            <h6 className="opName_Mobile">{post.name}</h6>
          </div>
          
          <div className="postInfo_Modal">
            <div className="eventDetails_Modal">
              <h2>{post.title}</h2>
              <div className="divider_Modal"/>
              <h6> <PlaceIcon className='icon' /> Salerno</h6>
            </div>
            <h4 className="opName">{post.name}</h4>
          </div>
        </div>
        <div className="scrollableCommentsDiv">
          <Comments postId={post.id} />
        </div>
        <div className="addCommentDiv">
          <form onSubmit={handleSubmit}>
            <EmojiEmotionsRoundedIcon className="emojiIcon" />
            <input
              type="text"
              name="comment"
              placeholder="Scrivi un commento..."
              onChange={handleChange}
            />
            <button disabled={sendCommentDisabled}>
              <SendRoundedIcon className="sendIcon"/>
            </button>
          </form>

        </div>
      </div>
    </div>

  );
};

export default PostModal;
