import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import WatchIcon from '@mui/icons-material/WatchLater';
import './HoursSelect.css';

export const hourRanges = [
  { label: "9-11", value: "9-11", key:1 },
  { label: "11-13", value: "11-13", key:2 },
  { label: "13-15", value: "13-15", key:3 },
];

const HoursSelect = ({ selectedHour, onHourChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [hovered, setHovered] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHourSelect = (hourRange) => {
    onHourChange(hourRange);
    handleClose();
  };


  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (

    <div className="flex flex-col justify-start items-start">

      <button

        id="hoursbutton"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={handleClick}
        className={`flex flex-row w-48 h-12 justify-between items-center rounded-md text-[#787579] hover:cursor-pointer ${hovered ? 'text-white bg-[#1C1B1F]' : 'text-[#1C1B1F] bg-[#E6E1E5]'}`}
        
      >

        <p className="font-medium text-base pl-2">

          {selectedHour ? selectedHour : "Seleziona"}

        </p>

        <WatchIcon className="rounded-r-md text-white bg-[#1C1B1F]" />

      </button>

      <Popover

        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}

      >        
        
        <div className="flex flex-col w-36 py-4 gap-2 rounded-md bg-[#E6E1E5]">

          {hourRanges.map((hourRange) => (

            <div key={hourRange?.value} className="flex flex-col gap-4">

              <button onClick={() => handleHourSelect(hourRange?.value)} className="flex w-full justify-center items-center font-medium text-[#1C1B1F] bg-[#E6E1E5] hover:text-white hover:bg-[#1C1B1F]" >

                {hourRange?.label}

              </button>

            </div>

          ))}

        </div>

      </Popover>

    </div>

  );
};

export default HoursSelect;
