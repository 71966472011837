import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";
import placeholder from '../../../../assets/PlaceholderImage.png';
import avellinoPic from '../../../../assets/Landing/Cities/Avellino.jpg';
import beneventoPic from '../../../../assets/Landing/Cities/Benevento.jpg';
import casertaPic from '../../../../assets/Landing/Cities/Caserta.webp';
import napoliPic from '../../../../assets/Landing/Cities/Napoli.webp';
import salernoPic from '../../../../assets/Landing/Cities/Salerno.jpg';

const citiesArray = [
  {
    label: "Salerno",
    value: "Salerno",
    key: "1",
    image: salernoPic,
  },
  {
    label: "Benevento",
    value: "Benevento",
    key: "2",
    image: beneventoPic,
  },
  {
    label: "Caserta",
    value: "Caserta",
    key: "3",
    image: casertaPic,
  },
  {
    label: "Napoli",
    value: "Napoli",
    key: "4",
    image: napoliPic,
  },
  {
    label: "Avellino",
    value: "Avellino",
    key: "5",
    image: avellinoPic,
  },
];

const CityCarousel = () => {

  return (

    <div className="carousel-container">

      <Carousel autoPlay infiniteLoop showThumbs={false}>

        {citiesArray.map((city) => (

          <div key={city.key}>
            
            <Link to={`/city/${city.value}`} className="block w-full h-full no-underline">

              <img
                src={city.image || placeholder}
                alt={city.label}
                className="object-cover h-full"
              />

              <p className="legend">{city.label}</p>

            </Link>

          </div>

        ))}

      </Carousel>

    </div>

  );
};

export default CityCarousel;