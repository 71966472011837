import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import avellinoPic from '../../../../assets/Landing/Cities/Avellino.jpg';
import beneventoPic from '../../../../assets/Landing/Cities/Benevento.jpg';
import casertaPic from '../../../../assets/Landing/Cities/Caserta.webp';
import napoliPic from '../../../../assets/Landing/Cities/Napoli.webp';
import salernoPic from '../../../../assets/Landing/Cities/Salerno.jpg';
import avellinoMap from '../../../../assets/Landing/Cities/Avellino.jpg';
import beneventoMap from '../../../../assets/Landing/Cities/Benevento.jpg';
import casertaMap from '../../../../assets/Landing/Cities/Caserta.webp';
import napoliMap from '../../../../assets/Landing/Cities/Napoli.webp';
import salernoMap from '../../../../assets/Landing/Cities/Salerno-Map.png';
import carouselExampleImg from '../../../../assets/Landing/Carousel-Example.png';
import RainbowBottom from '../../../../assets/Landing/Rainbow-Bottom.png';
import ScrollToTop from "../../../../ScrollToTop";

const citiesArray = [
  {
    cityName: "Salerno",    
    citySubName: "salernitana",
    image: salernoPic,
    mapimage: salernoMap,
    totemNumber: "5",
  },
  {
    cityName: "Benevento",
    citySubName: "beneventana",
    image: beneventoPic,
    mapimage: beneventoMap,
    totemNumber: "5",
  },
  {
    cityName: "Caserta",
    citySubName: "casertana",
    image: casertaPic,
    mapimage: casertaMap,
    totemNumber: "5",
  },
  {
    cityName: "Napoli",
    citySubName: "napoletana",
    image: napoliPic,
    mapimage: napoliMap,
    totemNumber: "5",
  },
  {
    cityName: "Avellino",
    citySubName: "avellinese",
    image: avellinoPic,
    mapimage: avellinoMap,
    totemNumber: "5",
  },
];

function CityPage () {
  const { cityName } = useParams();
  const navigate = useNavigate();  

  const goBack = () => {
    navigate(-1);
  };

  const goToRegister = () => {
    navigate('/register');
  };

  const goToLogin = () => {
    navigate('/login');
  };

  // Find the city object that matches the cityName from the URL parameter
  const city = citiesArray.find(city => city.cityName === cityName);

  if (!city) {
    // Handle the case where the city is not found, e.g., display an error message
    return (
      <div>
        City not found.
      </div>
    );
  }

  return (
    
    <div className="flex flex-col w-full justify-center items-center">

      <ScrollToTop />
      
      <div className="flex w-full h-full relative">

        <img src={city.image} alt={cityName} className="w-full h-full grayscale object-cover" />
      
        <div className="absolute top-0 left-0 w-full h-full gap-[10rem] bg-[#313033] bg-opacity-70 flex flex-col justify-start items-center">
       
          <div onClick={goBack} className="flex self-start ml-16 mt-[8rem] p-4 rounded-full text-white bg-opacity-70 hover:bg-[#313033] hover:cursor-pointer ">
       
            <ArrowBackIcon />
       
          </div>
       
          <div className="flex flex-col justify-center items-center gap-8">
        
            <h1 className="text-white font-bold text-[5rem]"> {cityName} </h1>
            <p className="text-white font-medium text-[1.8rem]"> Scopri la vita {city.citySubName} ed entra a far parte della community! </p>
         
          </div>

        </div>

      </div>

      <div className="flex flex-col justify-center items-center">

        <div className="flex flex-col self-start ml-20 my-16">
                
          <h1 className="text-[3rem] font-bold text-white"> Scopri i totem attivi! </h1>

          <div className="flex w-[40%]">

            <h4 className="text-[1.4rem] font-medium text-left text-white"> {cityName} dispone attualmente di {city.totemNumber} totem, diffusi sul territorio cittadino, che promuovono ad ogni ora i talenti cittadini.  </h4>
          
          </div>

        </div>

        <div className="w-[90%]">
        
          <img src={city.mapimage} alt={cityName} />
        
        </div>

      </div>

      <div className="flex flex-col justify-center items-center my-40">

        <div className="flex flex-col self-start ml-20 my-16">
                
          <h1 className="text-[3rem] font-bold text-white"> Entra a far parte della community di {cityName}! </h1>

          <div className="flex w-[40%]">

            <h4 className="text-[1.4rem] font-medium text-left text-white"> Scopri cosa fanno i 102 partner che hanno deciso di condividere la propria voce  </h4>
          
          </div>

        </div>

        <div className="w-full">
        
          <img src={carouselExampleImg} alt="Carousel Image Example" />
        
        </div>

      </div>
      
      <div className="flex flex-col w-full justify-center items-center">

        <div className="flex flex-col mt-[10rem] justify-center items-center gap-6 z-10">
                
          <h1 className="text-[5rem] font-bold text-white"> Fai sentire la tua voce! </h1>

          <div className="flex w-[40%]">

            <h4 className="text-[1.2rem] font-medium text-center text-white"> Promuovendo le attività locali speriamo di creare un senso più forte di comunità e orgoglio cittadino. Unisciti a VOCI e inizia a promuovere il tuo talento a un pubblico più ampio. Libera la tua voce ed entra a far parte della comunità del tuo territorio! </h4>

          </div>

          <button onClick={goToRegister} className="flex w-fit h-12 my-10 px-8 justify-center items-center rounded-3xl text-[#1C1B1F] bg-white border-2 border-[#1C1B1F] hover:text-white hover:bg-[#1C1B1F] hover:border-white">
        
            <p className="text-xl font-bold"> Iscriviti </p>
    
          </button>
          <button onClick={goToLogin} className="flex w-fit h-12 my-10 px-8 justify-center items-center rounded-3xl text-[#1C1B1F] bg-white border-2 border-[#1C1B1F] hover:text-white hover:bg-[#1C1B1F] hover:border-white">
      
            <p className="text-xl font-bold"> Accedi </p>

          </button>
          
        </div>

        <div className="flex w-[50%] -mt-[22rem]">

          <img src={RainbowBottom} alt="Rainbow Bottom" className="w-full h-full" />

        </div>

        <div className="flex flex-row w-[95%] h-12 -mt-12 gap-8 justify-start items-center bg-opacity-0">

          <button className="flex  text-[#787579] hover:text-white transition-all">

            <p className="text-base"> Privacy Policy </p>

          </button>

          <button className="flex  text-[#787579] hover:text-white transition-all">

            <p className="text-base"> Terms of Service </p>

          </button>

        </div>

      </div>

    </div>

  );
}

export default CityPage;
