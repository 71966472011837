import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import GoogleIcon from "../../assets/Icons/Google-Icon.png";
import AppleIcon from "../../assets/Icons/Apple-Icon.png";
import "./register.scss";
import { toast } from "react-toastify";

const Register = () => {
  const [inputs, setInputs] = useState({
    name: "",
    surname: "",
    email: "",
    password: "",
    confirmPassword: "",
    category: "",
    city: "",
    occupation: "",
    bio: ""
  });
  const [err, setErr] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [registrationPhase, setRegistrationPhase] = useState(0); //0 is reserved for initial state
  // const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const isValidEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  useEffect(() => { //TODO: Implementare modo di tornare indietro nelle fasi, possibilmente anche intercettando il pulsante indietro del browser --> Andranno rivisti anche gli stili/animazioni/classi per rendere tutto fluido e "a doppio senso"
    // if (registrationPhase === 1) {
    //   document.getElementById("register-phase1-card").classList.remove("previous");
    // }

    if (registrationPhase === 2) {
      document.getElementById("register-phase1-card").classList.add("previous");
      setTimeout(() => { //Wait for previous slide fade out
        document.getElementById("register-phase2-card").classList.add("current");
        document.getElementById("register-phase2-card").classList.remove("next");
      }, 300)
    }

    if (registrationPhase === 3) {
      document.getElementById("register-phase2-card").classList.remove("current");
      document.getElementById("register-phase2-card").classList.add("previous");
      setTimeout(() => { //Wait for previous slide fade out
        document.getElementById("register-phase3-card").classList.add("current");
        document.getElementById("register-phase2-card").classList.remove("next");
      }, 300)
    }


  }, [registrationPhase]);

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const resizeTextarea = (e) => {
    if (e) {
      e.target.style.height = "";
      e.target.style.height = e.target.scrollHeight + "px";
    } else {
      document.getElementById("bio-textarea").style.height = "";
      document.getElementById("bio-textarea").style.height = document.getElementById("bio-textarea").scrollHeight + "px";
    }
  }

  useEffect(() => {
    resizeTextarea(null);
  });

  // const query = useQuery();
  // const [error, setError] = useState("");
  // const [isLoading, setIsLoading] = useState(false);

  // check given code in the URL on redirect back from google auth
  // useEffect(() => {
  //   const auth = async (code) => {
  //     try {
  //       const url = `${env.REACT_APP_GOOGLE_LOGIN_ENDPOINT}?code=${code}`;
  //       const result = await axios.get(url);
  //       if (result?.data?.link) window.location = result.data.link;
  //       else throw new Error("No link received");
  //     } catch (error) {
  //       setError("Errore, Riprova.");
  //     }

  //     setError(null);
  //     setIsLoading(false);
  //   };

  //   setIsLoading(true);
  //   const code = query.get("code");
  //   if (!code) {
  //     setIsLoading(false);
  //     return;
  //   }

  //   auth(code);
  // }, [query]);

  /*
  const handlePasswordChange = (e) => {
    this.setState({password: e.target.value});
 };

 const handleUserNameChange = (e) => {
  this.setState({username: e.target.value});
};

const handleNameChange = (e) => {
  this.setState({name: e.target.value});
};


  const handleEmailChange = (e) => {
    this.setState({email: e.target.value});
 };

*/
  // const handleClick = async (e) => {
  //   e.preventDefault();

  //   const userData = {
  //     email: inputs.email,
  //     password: inputs.password,
  //     name: inputs.name,
  //     username: inputs.username,
  //   };

  //   try {
  //     await axios.post("http://localhost:8800/api/auth/register", userData);
  //   } catch (err) {
  //     setErr(err.response.data);
  //   }
  // };

  const handleSubmitPhase1 = (e) => {
    e.preventDefault();
    
    if (inputs.name.trim() == "") {
      setErr("Nome obbligatorio!");
      return;
    }
    if (inputs.surname.trim() == "") {
      setErr("Cognome obbligatorio!");
      return;
    }
    if (inputs.email.trim() == "") {
      setErr("Email obbligatoria!");
      return;
    }
    if (!isValidEmail(inputs.email.trim())) {
      setErr("Email non valida!");
      return;
    }
    if (inputs.password.trim() == "") {
      setErr("Password obbligatoria!");
      return;
    }
    if (inputs.password.trim().length < 8) {
      setErr("Password troppo corta! (min. 8 caratteri)");
      return;
    }
    if (inputs.password.trim() != inputs.confirmPassword.trim()) {
      setErr("Le password non coincidono!");
      return;
    }

    setErr(null);
    setRegistrationPhase(2);

  }

  const handleSubmitPhase2 = (e) => {
    e.preventDefault();

    setRegistrationPhase(3);
  }

  const handleSubmitPhase3 = async (e) => {
    e.preventDefault();

    try {
      await axios.post("https://api.voiceint.wwwnl1-ts107.a2hosted.com/api/auth/register", 
      {
        email: inputs.email,

        password: inputs.password,
        name: inputs.name,
        surname: inputs.surname,
        category: inputs.category,
        bio: inputs.bio,
        occupation: inputs.occupation,
        city: inputs.city,
      }, 
      {
        withCredentials: true,
        credentials: "same-origin",
      });

      // Show success toast (optional)
      toast.success("Registrazione completa!\n\nÈ ora possibile effettuare il login.", {
        position: 'top-center',
        autoClose: 3000, // Adjust as needed
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      navigate("/login");
    } catch (err) {
      console.log(err);
      alert("Errore durante la registrazione.\n\nSi prega di riprovare.\n\nAltri dettagli: " + (err?.response?.data ?? "N/A"));
      return;
    }

  
  }

  const handleAppleRegister = (e) => {
    //TODO
    alert('TODO');
  }

  const handleGoogleRegister = (e) => {
    window.open("https://api.voiceint.wwwnl1-ts107.a2hosted.com" + "/auth/google", "_self");
  }

  return (
    <div className="registerContainer">
      <div id="register-phase1-card" className="registerCard">
          <h1 className="registerTitle">Crea un account</h1>
          <p className="registerSubTitle">Sei già una voce? <Link className="registerLoginLink" to="/login">Accedi!</Link></p>

          <form className="registerForm">
            <div className="registerDoubleFieldRow">
              <input
                className="registerInput"
                type="text"
                placeholder="Nome"
                name="name"
                onChange={handleChange}
              />

              <input
                className="registerInput"
                type="text"
                placeholder="Cognome"
                name="surname"
                onChange={handleChange}
              />
            </div>
            <input
              className="registerInput"
              type="email"
              placeholder="Email"
              name="email"
              onChange={handleChange}
            />
            <input
              className="registerInput"
              type="password"
              placeholder="Password"
              name="password"
              onChange={handleChange}
            />
            <input
              className="registerInput"
              type="password"
              placeholder="Conferma Password"
              name="confirmPassword"
              onChange={handleChange}
            />
            <p className="registerErrorMsg">{err && err}</p>

            <button className="registerBtn" onClick={handleSubmitPhase1}>CONTINUA</button>
          </form>

          <p className="registerDividerText">oppure</p>

          {/* <button className="alternativeRegisterBtn" onClick={handleAppleRegister} disabled={isLoading}>
            <img className="alternativeRegisterIcon" src={AppleIcon} alt="" />
            ISCRIVITI CON APPLE
          </button> */}

          <button className="alternativeRegisterBtn" onClick={handleGoogleRegister} disabled={isLoading}>
            <img className="alternativeRegisterIcon" src={GoogleIcon} alt="" />
            ISCRIVITI CON GOOGLE
          </button>
      </div>

    <div id="register-phase2-card" className="registerCard next">
        <h2 className="registerSecondaryTitle">Che tipo di voce sei</h2>

        <form className="registerForm">
          <div className="registerSecondaryField">
            <h3>Scegli una categoria</h3>
            <h4>Le categorie ci aiutano a definire l'ambito di cui fai parte.</h4>
            {/* //TODO: Sostituire il select sotto con un "select" fatto a mano con divs (o usando mui), per avere completo controllo dello styling. Attualmente non è fisicamente possibile visto che il dropdown è gestito dall'OS */}
            <select className='registerSelect' name="category" onChange={handleChange}>
              <option value="" selected disabled hidden>Categoria</option>
              <option value="Ambiente"><a>Ambiente</a></option>
              <option value="Centri culturali"><a>Centri culturali</a></option>
              <option value="Mobilità"><a>Mobilità</a></option>
              <option value="Servizi per la comunità"><a>Servizi per la comunità</a></option>
              <option value="Talenti"><a>Talenti</a></option>
            </select>
          </div>

          <div className="registerSecondaryField">
            <h3>In quale città sei maggiormente attivo?</h3>
            <h4>Ti consigliamo di scegliere la città in cui vivi o verso la quale provi più interesse.</h4>
            {/* //TODO: Sostituire il select sotto con un "select" fatto a mano con divs (o usando mui), per avere completo controllo dello styling. Attualmente non è fisicamente possibile visto che il dropdown è gestito dall'OS */}
            <select className='registerSelect' name="city" onChange={handleChange}>
              <option value="" selected disabled hidden>Città</option>
              <option value="Salerno"><a>Salerno</a></option>
              <option value="Benevento"><a>Benevento</a></option>
              <option value="Napoli"><a>Napoli</a></option>
              <option value="Caserta"><a>Caserta</a></option>
              <option value="Avellino"><a>Avellino</a></option>
            </select>
          </div>

          <button className="registerBtn" onClick={handleSubmitPhase2} disabled={inputs.category == "" || inputs.city == ""}>CONTINUA</button>
        </form>
    </div>

    <div id="register-phase3-card" className="registerCard next">
        <h2 className="registerSecondaryTitle">Che tipo di voce sei</h2>

        <form className="registerForm">
          <div className="registerSecondaryField">
            <h3>Qual è la tua occupazione?</h3>
            <input
                className="registerSecondaryInput"
                type="text"
                placeholder="Es: Designer, Attore, Chef, Cantante…"
                name="occupation"
                onChange={handleChange}
              />
          </div>

          <div className="registerSecondaryField">
            <h3>Parlaci un po' di te!</h3>
            <textarea
              type="text"
              name="bio"
              id="bio-textarea"
              className="registerTextArea"
              placeholder="Descrivi chi sei e quello che fai sul tuo territorio… Fai sentire la tua voce!"
              onChange={handleChange}
              rows="4"
              onInput={(e) => resizeTextarea(e)}
            />
          </div>

          <button className="registerBtn" onClick={handleSubmitPhase3} disabled={inputs.occupation.trim() == "" || inputs.bio.trim() == ""}>REGISTRATI</button>
        </form>
    </div>

    </div>
  );
};

export default Register;


// OLD
// return (
//   <div className="register">
//     <div className="card">
//       <div className="right">
//         <h1>Register</h1>
//         <form onSubmit={handleClick}>
//           <input
//             type="text"
//             placeholder="Username"
//             name="username"
//             onChange={handleChange}
//           />
//           <input
//             type="email"
//             placeholder="Email"
//             name="email"
//             onChange={handleChange}
//           />
//           <input
//             type="password"
//             placeholder="Password"
//             name="password"
//             onChange={handleChange}
//           />
//           <input
//             type="text"
//             placeholder="Name"
//             name="name"
//             onChange={handleChange}
//           />
//           {err && err}
//           <button type="submit">Register</button>
//         </form>

//         <button onClick={handleLogin} disabled={isLoading} className="flex">
//           <img className="w-6 h-6" src={googleicon} alt="" />
//         </button>
//         {error && <div>{error}</div>}

//         <span>Do you have an account?</span>
//         <Link to="/login">
//           <button>Login</button>
//         </Link>
//       </div>
//     </div>
//   </div>
// );