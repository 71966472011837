import { useContext, useEffect, useState } from "react";
import "./loading.scss";
import { AuthContext } from "../../context/authContext";

const Loading = () => {
  const [showGoBack, setShowGoBack] = useState(false);
  const { currentUser, setCurrentUser } = useContext(AuthContext);

  useEffect(() => {
    setTimeout(() => {
      setShowGoBack(true);
    }, 5000)
  }, []);

  useEffect(() => {
    const getUser = () => {
      fetch("https://api.voiceint.wwwnl1-ts107.a2hosted.com/auth/google/success", {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
        .then((response) => {
          console.log(response);
          if (response.status === 200) return response.json();
          throw new Error("authentication has failed!");
        })
        .then((resObject) => {
          setCurrentUser(resObject.user);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getUser();
  }, [])

  useEffect(() => {
    if (currentUser != null) {
      window.location.replace("https://voiceint.wwwnl1-ts107.a2hosted.com/home");
    }
  }, [currentUser])

  return (
    <div className="loadingContainer">
      <h2>Recupero credenziali</h2>
      {showGoBack &&
        <a href="/login">Non funziona? Torna al login</a>
      }
    </div>
  );
};

export default Loading;