import { useState } from "react";
import { Form } from 'react-router-dom';
import VoceIconAntracite from "../../../assets/Icons/VOCE-Icon-Antracite.png";
import VoceIconWhite from "../../../assets/Icons/VOCE-Icon-White.png";
import MeetAndGreetIconAntracite from "../../../assets/Icons/Meet-&-Greet-Icon-Antracite.png";
import MeetAndGreetIconWhite from "../../../assets/Icons/Meet-&-Greet-Icon-White.png";

const View1 = ( { menu, changeView1, changeView2 } ) => {

  const [hovered, setHovered] = useState(false);
  const [hovered2, setHovered2] = useState(false);

  return (
    
    <div className='flex flex-col gap-4'>

      <div className='flex'>

        <p className='font-bold text-[#1C1B1F]'>Crea Nuovo {menu}</p>

      </div>

      <Form className='flex flex-row w-[50rem] gap-6'>

        <div 
        
          onClick={changeView1} 
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          className={`flex flex-col w-1/2 pt-8 pb-20 justify-start items-center border-[1px] border-[#787579] rounded-[4px] gap-6 hover:cursor-pointer ${hovered ? 'bg-[#1C1B1F]' : 'bg-white'}`}
        
        >

          <div className='flex flex-col w-full justify-center items-center gap-2'>

            <img src={hovered ? VoceIconWhite : VoceIconAntracite} alt="voceicon" className='w-max h-16'/>

            <p className={`font-bold ${hovered ? 'text-white' : 'text-[#1C1B1F]'}`}> Voce </p>

          </div>

          <div className='flex'>

            <p className={`font-bold text-center w-60 ${hovered ? 'text-white' : 'text-[#1C1B1F]'}`}> Pubblica contenuti e fatti conoscere nella tua città! </p>

          </div>

        </div>

        <div 
        
          onClick={changeView2} 
          onMouseEnter={() => setHovered2(true)}
          onMouseLeave={() => setHovered2(false)}
          className={`flex flex-col w-1/2 pt-8 pb-20 justify-start items-center border-[1px] border-[#787579] rounded-[4px] gap-6 hover:cursor-pointer ${hovered2 ? 'bg-[#1C1B1F]' : 'bg-white'}`}
        
        >

          <div className='flex flex-col w-full justify-center items-center gap-2'>

            <img src={hovered2 ? MeetAndGreetIconWhite : MeetAndGreetIconAntracite} alt="meetandgreeticon" className='w-max h-16'/>
            
            <p className={`font-bold ${hovered2 ? 'text-white' : 'text-[#1C1B1F]'}`}> Meet & Greet </p>

          </div>

          <div className='flex'>

            <p className={`font-bold text-center w-60 ${hovered2 ? 'text-white' : 'text-[#1C1B1F]'}`}> Incontra la tua community! Organizza un evento presso uno dei totem disponibili! </p>

          </div>

        </div>

      </Form>

    </div>

  );
}

export default View1;