import { useContext, useEffect, useState } from "react";
import { fetchPostsByUserId } from '../../FetchCalls';
import { useParams } from 'react-router-dom';
import { makeRequest } from "../../axios";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { AuthContext } from "../../context/authContext";
import { TailSpin } from 'react-loader-spinner';
import Posts from "../../components/posts/Posts";
import Update from "../../components/update/Update";
import CreateIcon from '@mui/icons-material/Create';
import PlaceIcon from '@mui/icons-material/Place';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import './profile.scss'

const Profile = () => {
  const [openUpdate, setOpenUpdate] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const [results, setResults] = useState([]);
  const userId = useParams()?.id;
  // console.log("UserId from URL:", userId);
  // console.log("CurrentUser ID :", currentUser.id);

  // Function to fetch user data
  const fetchUserData = async () => {
    try {
      // Fetch user data
      const res = await makeRequest.get("/users?userId=" + userId);
      const userData = res.data;
      await setUser(userData);
      
    } catch (error) {
      console.error("Impossibile caricare i dati dell'utente", error);
    }
  };

  // calls 
  const { isLoading, data , isSuccess } = useQuery(["user",userId], () =>
    makeRequest.get("/users?userId=" + userId).then((res) => {
      return res.data;
    })
  );
  const { isLoading: rIsLoading, data: relationshipData } = useQuery(
    ["relationship"],
    () =>
      makeRequest.get("/relationships?followedUserId=" + userId).then((res) => {
        return res.data;
      })
  );

  const isFollowing = Array.isArray(relationshipData) && relationshipData.includes(currentUser.id);

  const mutation = useMutation(
    (following) => {
      if (following)
        return makeRequest.delete("/relationships?userId=" + userId);
      return makeRequest.post("/relationships", { userId });
    },
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries(["relationship"]);
      },
    }
  );
  const handleFollow = () => {
    mutation.mutate(relationshipData.includes(currentUser.id));
  };
  const allPosts = useQuery(["posts", userId], () => fetchPostsByUserId(userId), {
    staleTime: 1000 * 60 * 0.2, // 5 minutes
  });

  useEffect(() => {
    if (isLoading) return;

    document.getElementById("profile").style.overflow = openUpdate ? "hidden" : "auto"; //Disable scrolling
    document.getElementById("profile").style.touchAction = openUpdate ? "none" : "auto"; //Disable scrolling for touch devices (especially iPhones)
  }, [openUpdate]);

  // end of calls 

  return (
    <div id="profile" className="profile"> 
      {isLoading  && allPosts.isLoading && !isSuccess ? (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <TailSpin
            color="#FFFFFF" // Set the loader color
            height={80} // Set the height of the loader
            width={80} // Set the width of the loader
          />
        </div>
      ) : (
        // <>
          // <div className="images">
          //   <img src={"/upload/"+data?.profilePic} alt="" className="profilePic" />
          // </div>

          <div id="profileContainer" className="profileContainer">

            <div className="uInfo">

              <picture className='pfp'>

                <source media="(min-width:0px)" srcset={"/upload/"+data?.profilePic} />

                <img src={"/upload/"+data?.profilePic} alt="" />

              </picture>
              
              <div className='userDataDiv'>
             
                <div className='userDataHeader'>
               
                  <img className='pfpMobile' src={"/upload/"+data?.profilePic} alt="" />
               
                  <div className='userDataHeaderData'>
                 
                    <div className='titleDiv mb-6'>

                      <h4 className='font-bold text-2xl'> {data?.name || "nome"} {data?.surname || "cognome"} </h4>
                      
                      {userId != currentUser?.id ? ( // Check if the user is different from the current user

                        <button onClick={handleFollow}>
                          
                          <PersonAddIcon className="icon" />{' '}
                          <a> {isFollowing ? "Non Seguire" : "Segui"}</a>

                        </button>

                      ) : (

                        <button onClick={() => setOpenUpdate(true)}>
                          
                          <CreateIcon className="icon" /> <a> Modifica profilo </a>

                        </button>

                      )}
                  
                    </div>
                  
                    <div className='detailsDiv'>
                     
                      <h5> {data?.occupation || "occupazione"} </h5>
                      
                      <div className='divider' />
                     
                      <h6> <PlaceIcon className='icon' /> {data?.city || "città"} </h6> {/* TODO: Questo h6 sarà probabilmente circondato da una div per poterlo allineare con l'icona attualmente mancante */}
                    
                    </div>

                  </div>

                </div>

                <p> {data?.bio || "biografia"}</p>

              </div>

              {/* <div className="left">
                <a href="http://facebook.com">
                  <FacebookTwoToneIcon fontSize="large" />
                </a>
                <a href="http://facebook.com">
                  <InstagramIcon fontSize="large" />
                </a>
                <a href="http://facebook.com">
                  <TwitterIcon fontSize="large" />
                </a>
                <a href="http://facebook.com">
                  <LinkedInIcon fontSize="large" />
                </a>
                <a href="http://facebook.com">
                  <PinterestIcon fontSize="large" />
                </a>
              </div>
              <div className="center">
                <span>{data?.name}</span>
                <div className="info">
                  <div className="item">
                    <PlaceIcon />
                    <span>{data?.city}</span>
                  </div>
                  <div className="item">
                    <LanguageIcon />
                    <span>{data?.website}</span>
                  </div>
                </div>
                {rIsLoading ? (
                  "loading"
                ) : userId === currentUser?.id ? (
                  <button onClick={() => setOpenUpdate(true)}>update</button>
                ) : (
                  <button onClick={handleFollow}>
                    {relationshipData.includes(currentUser?.id)
                      ? "Following"
                      : "Follow"}
                  </button>
                )}
              </div>
              <div className="right">
                <EmailOutlinedIcon />
                <MoreVertIcon />
              </div> */}
              
            </div>
            {allPosts.isSuccess && (
            <Posts posts={allPosts.data || []} />
            )}

            <div>

            </div>

          </div>

        // </>

      )}

      {/* {openUpdate && isSuccess && <Update setOpenUpdate={setOpenUpdate} user={data} />} */}

      {openUpdate && isSuccess && <Update setOpenUpdate={setOpenUpdate} user={data} onUpdateUser={fetchUserData} />}

    </div>

  );
};

export default Profile;
